import React from 'react'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'
import searchActionCreators from '../../../redux/reducers/search/actions'
import thunkCreators from '../../../redux/thunks'

const ResetButton = (props) => {
  const dispatch = useDispatch()
  const handleButtonClick = () => {
    dispatch(searchActionCreators.resetSearchForm())
    dispatch(thunkCreators.filterGeography())
  }

  return (
    <Button onClick={handleButtonClick} sx={props.sx} variant="contained">
      Сбросить
    </Button>
  )
}

export default ResetButton
