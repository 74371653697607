import { Typography } from '@mui/material'
import React from 'react'

const Title = (props) => {
  return (
    <Typography variant="h3" component="h1" sx={{ textAlign: 'center', mb: 2, ...props.sx }}>
      {props.children}
    </Typography>
  )
}

export default Title
