import subjects from '../../../data/subjects.json'
import districts from '../../../data/federalDistricts.json'

import deepcopy from '../../../utils/deepcopy'
import { geographyActionTypes } from './actions'

const initalState = {
  subjects,
  districts,
  points: [],
}

const geographyReducer = (state = deepcopy(initalState), action) => {
  switch (action.type) {
    case geographyActionTypes.SET_MAP_DISTRICTS:
      return {
        ...state,
        districts: action.payload.districts,
      }

    case geographyActionTypes.SET_MAP_POINTS:
      return {
        ...state,
        points: action.payload.points,
        mainPoints: action.payload.points
      }

    case geographyActionTypes.SET_MAP_SUBJECTS:
      return {
        ...state,
        subjects: action.payload.subjects,
      }

    case geographyActionTypes.DELETE_MAP_POINT:
      return {
        ...state,
        points: state.points.filter(point => {
          return point.additionalInfo.userId !== action.payload.userId
        })
      }

    case geographyActionTypes.ADD_MAP_POINT:
      if (state.points.some(point => {
        return point._id === action.payload.point._id
      })) {
        return state
      }

      return {
        ...state,
        points: [...state.points, action.payload.point]
      }

    // case geographyActionTypes.RESET_GEOGRAPHY:
    //   return deepcopy(initalState)

    // case geographyActionTypes.FILTER_SUBJECTS_BY_DISTRICT_NAME:
    //   const district = state.districts.find((district) => {
    //     return district.name === action.payload.districtName
    //   })

    //   if (!district) {
    //     return {
    //       ...state,
    //       subjects: deepcopy(initalState.subjects),
    //     }
    //   }

    //   const allSubjects = deepcopy(subjects)
    //   const subjectsInDistrict = []

    //   allSubjects.forEach((s) => {
    //     if (district.subjects.includes(s.geometry.id)) {
    //       subjectsInDistrict.push(s)
    //     }
    //   })

    //   return {
    //     ...state,
    //     subjects: subjectsInDistrict,
    //   }

    // case geographyActionTypes.FIND_SUBJECT_BY_SUBJECT_NAME:
    //   const subject = state.subjects.find((subject) => {
    //     return (
    //       subject.geometry.properties.hintContent === action.payload.subjectName
    //     )
    //   })

    //   if (!subject) {
    //     return state
    //   }

    //   return {
    //     ...state,
    //     subjects: [subject],
    //   }

    // case geographyActionTypes.FILTER_POINTS_ACCORDING_TO_SUBJECTS:
    //   const subjectsIds = state.subjects.map((subject) => {
    //     return subject.geometry.id
    //   })

    //   const points = deepcopy(initalState.points).filter((point) => {
    //     return subjectsIds.includes(point.subjectId)
    //   })

    //   return {
    //     ...state,
    //     points,
    //   }

    default:
      return state
  }
}

export default geographyReducer
