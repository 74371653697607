import appDataActionCreators, { appDataActionTypes } from './actions'

const initialState = {
  specialities: [],
  scientificStatuses: [],
  education: [],
  points: [],
  academicTitles: [],
  universities: [],
  languages: [],
  languageLevels: [],
  scientificInterests: [],
  agePeriods: [],
  availableScientists: []
}

const appDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case appDataActionTypes.SET_EDUCATION:
      return {
        ...state,
        education: action.payload.education,
      }

    case appDataActionTypes.SET_SPECIALITIES:
      return {
        ...state,
        specialities: action.payload.specialities,
      }

    case appDataActionTypes.SET_SCIENTIFIC_STATUSES:
      return {
        ...state,
        scientificStatuses: action.payload.scientificStatuses,
      }

    case appDataActionTypes.SET_POINTS:
      return {
        ...state,
        points: action.payload.points,
      }

    case appDataActionTypes.SET_ACADEMIC_TITLES: {
      return {
        ...state,
        academicTitles: action.payload.academicTitles
      }
    }

    case appDataActionTypes.SET_UNIVERSITIES: {
      return {
        ...state,
        universities: action.payload.universities
      }
    }

    case appDataActionTypes.SET_AGE_PERIODS: {
      return {
        ...state,
        agePeriods: action.payload.agePeriods
      }
    }

    case appDataActionTypes.SET_SCIENTIFIC_INTERESTS: {
      return {
        ...state,
        scientificInterests: action.payload.scientificInterests
      }
    }

    case appDataActionTypes.SET_LANGUAGES: {
      return {
        ...state,
        languages: action.payload.languages
      }
    }

    case appDataActionTypes.SET_LANGUAGE_LEVELS: {
      return {
        ...state,
        languageLevels: action.payload.languageLevels
      }
    }

    case appDataActionTypes.SET_AVAILABLE_SCIENTISTS: {
      return {
        ...state,
        availableScientists: action.payload.scientists
      }
    }

    default:
      return state
  }
}

export default appDataReducer
