import React, { useState } from "react";
import { Button, Typography, Container } from "@mui/material/";
import Subtitle from "../../common/Subtitle/Subtitle";
import Checkbox from "@mui/material/Checkbox";
import { red } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import thunkCreators from "../../../redux/thunks";

const DeleteAccount = (props) => {
  const dispatch = useDispatch();
  const { userId, token } = useSelector((state) => state.auth);
  const [userAgree, setUserAgree] = useState(false);

  const handleCheckboxChange = (event) => {
    setUserAgree(event.target.checked);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    dispatch(thunkCreators.deleteUser(userId, token));
  };

  return (
    <Container maxWidth="xs" sx={props.sx}>
      <form onSubmit={handleFormSubmit}>
        <Subtitle>Удаление аккаунта</Subtitle>
        <Typography component="span" sx={{ color: red[600] }}>
          Подтверждаю удаление аккаунта
          <Checkbox
            required
            checked={userAgree}
            onChange={handleCheckboxChange}
            sx={{
              color: red[600],
              "&.Mui-checked": {
                color: red[600],
              },
            }}
          />
        </Typography>
        <Button disabled={!userAgree} variant="contained" color="error" type="submit">
          Удалить
        </Button>
      </form>
    </Container>
  );
};

export default DeleteAccount;
