import React from "react";
import { useSelector } from "react-redux";
import Autocomplete from "../common/Autocomplete/Autocomplete";

const ScientificInterestAutocomplete = (props) => {
    const { inputValue, onInputChange, value, onChange, name, required, sx, label, id, filterValues } = props;
    const scientificInterests = useSelector((state) => state.appData.scientificInterests);

    const filterValuesName = filterValues.map((filterValue) => filterValue.name)

    const scientificInterestsSelectOptions = scientificInterests.map((speciality) => {
        return {
            key: speciality._id,
            value: speciality._id,
            text: speciality.name,
        }
    }).filter(interest => {
        return !filterValuesName.includes(interest.text)
    })

    const options = scientificInterestsSelectOptions
        .map((status) => {
            return status.text
        })
        .sort()

    return (
        <Autocomplete
            label={label ? label : "Научные интересы"}
            options={options}
            inputValue={inputValue}
            onInputChange={onInputChange}
            name={name}
            sx={sx}
            value={value}
            onChange={onChange}
            required={required}
            freeSolo
        />
    );
};

export default ScientificInterestAutocomplete;
