import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import searchActionCreators from '../../../redux/reducers/search/actions'
import thunkCreators from '../../../redux/thunks'
import Select from '../../common/Select/Select'

const DistrictSelect = (props) => {
  const dispatch = useDispatch()

  const value = useSelector((state) => state.search.district)
  const districts = useSelector((state) => state.geography.districts)
  const options = districts.map((district) => {
    return district.name
  })

  const districtsSelectHandler = (event) => {
    dispatch(
      searchActionCreators.changeSearchField('district', event.target.value),
    )
    dispatch(thunkCreators.filterGeography())
  }

  return (
    <Select
      sx={props.sx}
      id="districtSelect"
      label="Округ"
      value={value}
      onChange={districtsSelectHandler}
      options={options}
      defaultValue=""
    />
  )
}

export default DistrictSelect
