import React, {useEffect} from 'react'
import { YMaps, Map, Clusterer } from 'react-yandex-maps'
import Placemarks from '../MapPlacemarks/MapPlacemarks'
import RuSubjects from '../MapRuSubjects/MapRuSubjects'
import api from "../../../api";
import {useDispatch} from "react-redux";
import geographyActionCreators from "../../../redux/reducers/geography/actions";

const MainPageMap = () => {
  const dispatch = useDispatch()

  useEffect(() => {
      api.point.getAll().then(res => dispatch(geographyActionCreators.setMapPoints(res.data.points)))
  }, [])

  const mapState = { center: [63, 100], zoom: 3 }
  const clustererOptions = {
    clusterDisableClickZoom: true,
    clusterOpenBalloonOnClick: true,
    clusterBalloonContentLayoutHeight: 350,
    clusterBalloonContentLayoutWidth: 600,
  }
  const clusterClickHandler = (event) => {
      event.preventDefault()

  }

  return (
    <YMaps>
      <Map
        style={{
          width: '100%',
          borderRadius: '5px',
          minHeight: '450px',
          marginTop: '20px',
        }}
        state={mapState}
      >
        <RuSubjects />
        <Clusterer
          options={clustererOptions}
          onClick={clusterClickHandler}
          modules={['clusterer.addon.balloon']}
        >
          <Placemarks />
        </Clusterer>
      </Map>
    </YMaps>
  )
}

export default MainPageMap
