import {Avatar, Box, Container, ListItem, ListItemButton, ListItemText, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import Title from "../../components/common/Title/Title";
import api from "../../api";
import React, {useEffect, useState} from "react";

const UserPage = () => {
    const {id} = useParams();

    const [userData, setUserData] = useState({})

    useEffect(() => {
        api.user.getById(id).then(res => {
            setUserData(res.data)
            console.log(res.data)
        })
    }, [id])

    const getDate = (date) => {
        return `${new Date(date).getFullYear()}.${new Date(date).getMonth() + 1}.${new Date(date).getDate()}`
    }

    if (Object.entries(userData).length === 0) return <Title sx={{mt: 6}}>Загрузка...</Title>

    return <Container maxWidth="md" sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2}}>
        <Title sx={{mt: 6}}>Профиль</Title>
        <Avatar sx={{width: 240, height: 240, border: "1px solid #c4c4c4", fontSize: 24}} alt="s"
                src={`/api/files/${id}/${userData?.avatar}`}/>
        <Typography variant='h4'>{userData?.name} {userData?.surname} {userData?.parentName}</Typography>
        {
            userData?.dateOfBirth &&
            <Typography variant='subtitle1'>Дата рождения: {getDate(userData?.dateOfBirth)}</Typography>
        }
        {
            userData?.scientificStatus &&
            <Typography variant='subtitle1'>Ученая степень: {userData?.scientificStatus}</Typography>
        }
        {
            userData?.speciality && <Typography variant='subtitle1'>Специальность: {userData?.speciality}</Typography>
        }
        {
            userData?.academicTitle &&
            <Typography variant='subtitle1'>Ученое звание: {userData?.academicTitle}</Typography>
        }

        {
            userData?.university && <Typography variant='subtitle1'>Университет: {userData?.university}</Typography>
        }
        {
            userData?.scientificStatus &&
            <Typography variant='subtitle1'>Ученая степень: {userData?.scientificStatus}</Typography>
        }
        {
            userData?.honoryTitle && <Typography variant='subtitle1'>Почетное звание: {userData?.honoryTitle}</Typography>
        }
        {
            (userData?.phoneNumber || userData?.email || userData?.mainLanguage || userData?.secondLanguage || userData?.spinCode) &&
            <Typography variant='h4'>Основное</Typography>
        }
        {
            userData?.phoneNumber && <Typography variant='subtitle1'>Номер телефона: {userData?.phoneNumber}</Typography>
        }
        {
            userData?.email && <Typography variant='subtitle1'>Электронная почта: {userData?.email}</Typography>
        }
        {
            userData?.mainLanguage && <Typography variant='subtitle1'>Основной язык: {userData?.mainLanguage}</Typography>
        }
        {
            userData?.mainLanguage && userData?.mainLanguageLevel &&
            <Typography variant='subtitle1'>Уровень владения основным языком: {userData?.mainLanguageLevel}</Typography>
        }
        {
            userData?.secondLanguage &&
            <Typography variant='subtitle1'>Дополнительный язык: {userData?.secondLanguage}</Typography>
        }
        {
            userData?.secondLanguage && userData?.secondLanguageLevel &&
            <Typography variant='subtitle1'>Уровень владения дополнительным
                языком: {userData?.secondLanguageLevel}</Typography>
        }
        {
            userData?.spinCode &&
            <Typography variant='subtitle1'>SPIN-код (eLibrary.ru): {userData?.spinCode}</Typography>
        }
        {
            userData?.scientificInterests && (JSON.parse(userData?.scientificInterests)?.length > 0 && <Typography variant='h4'>Научные интересы</Typography>)
        }
        {
            userData?.scientificInterests && (JSON.parse(userData?.scientificInterests)?.length > 0 && JSON.parse(userData?.scientificInterests)?.map((interest, index) => {
                return <ListItem sx={{
                    border: '1px solid black',
                    borderRadius: 3,
                    p: 1,
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between'
                }} key={index} variant='subtitle1'>
                    <ListItemText>{interest.name}</ListItemText>
                    {
                        interest.file && <a href={`/api/files/${id}/${interest.file}`}>Скачать файл</a>
                    }
                </ListItem>
            }))
        }
        {

            userData?.scientificCooperation && (JSON.parse(userData?.scientificCooperation)?.length > 0 && <Typography variant='h4'>Научное сотрудничество</Typography>)
        }
        {
            userData?.scientificCooperation && (JSON.parse(userData?.scientificCooperation)?.length > 0 && JSON.parse(userData?.scientificCooperation)?.map((scientist, index) => {
                return <ListItem sx={{
                    border: '1px solid black',
                    borderRadius: 3,
                    p: 1,
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between'
                }} key={index} variant='subtitle1'>
                    <ListItemText>{scientist.text}</ListItemText>
                    <Link to={`/user/${scientist.value}`}>Перейти в профиль</Link>
                </ListItem>
            }))
        }
        {
            (userData?.publications || userData?.participationInProjects || userData?.participationInGrantsPublications || userData?.participationInConferences) &&
            <Typography variant='h4'>Публикационная активность</Typography>
        }
        {
            userData?.publications && <Typography variant='subtitle1'>Публикации (Scopus, Web of science, ВАК,
                РИНЦ): {userData?.publications}</Typography>
        }
        {
            userData?.participationInProjects &&
            <Typography variant='subtitle1'>Участие в проектах: {userData?.participationInProjects}</Typography>
        }
        {
            userData?.participationInGrantsPublications &&
            <Typography variant='subtitle1'>Участие в грантах: {userData?.participationInGrantsPublications}</Typography>
        }
        {
            userData?.participationInConferences && <Typography variant='subtitle1'>Участие в Международных/Всероссийских
                конференциях: {userData?.participationInConferences}</Typography>
        }
        {
            userData?.workExperience && <Typography variant='h4'>Опыт работы</Typography>
        }
        {
            userData?.workExperience && JSON.parse(userData?.workExperience)?.map((work, index) => {
                return <ListItem sx={{
                    border: '1px solid black',
                    borderRadius: 3,
                    p: 1,
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column'
                }} key={index} variant='subtitle1'>
                    <ListItemText>Должность: {work.post}</ListItemText>
                    <ListItemText>Место работы: {work.job}</ListItemText>
                    <ListItemText>Дата начала: {getDate(work.dateStart)}</ListItemText>
                    {!work.isCurrentlyWorking && <ListItemText>Дата конца: {getDate(work.dateEnd)}</ListItemText>}
                    {work.isCurrentlyWorking && <ListItemText>По настоящее время</ListItemText>}
                </ListItem>
            })
        }
        {
            (userData?.schools || userData?.secondaryEducation || userData?.higherEducation) &&
            <Typography variant='h4'>Образование</Typography>
        }
        {
            userData?.schools && <Typography variant='h6'>Школа</Typography>
        }
        {
            userData?.schools && JSON.parse(userData?.schools)?.map((school, index) => {
                return <ListItem sx={{
                    border: '1px solid black',
                    borderRadius: 3,
                    p: 1,
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column'
                }} key={index} variant='subtitle1'>
                    <ListItemText>Название: {school.name}</ListItemText>
                    <ListItemText>Дата начала: {getDate(school.dateStart)}</ListItemText>
                    <ListItemText>Дата конца: {getDate(school.dateEnd)}</ListItemText>
                </ListItem>
            })
        }
        {
            userData?.secondaryEducation && <Typography variant='h6'>Среднее образование</Typography>
        }
        {
            userData?.secondaryEducation && JSON.parse(userData?.secondaryEducation)?.map((education, index) => {
                return <ListItem sx={{
                    border: '1px solid black',
                    borderRadius: 3,
                    p: 1,
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column'
                }} key={index} variant='subtitle1'>
                    <ListItemText>Название: {education.name}</ListItemText>
                    <ListItemText>Специальность: {education.speciality}</ListItemText>
                    <ListItemText>Дата начала: {getDate(education.dateStart)}</ListItemText>
                    <ListItemText>Дата конца: {getDate(education.dateEnd)}</ListItemText>
                </ListItem>
            })
        }
        {
            userData?.higherEducation && <Typography variant='h6'>Высшее образование</Typography>
        }
        {
            userData?.higherEducation && JSON.parse(userData?.higherEducation)?.map((education, index) => {
                return <ListItem sx={{
                    border: '1px solid black',
                    borderRadius: 3,
                    p: 1,
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column'
                }} key={index} variant='subtitle1'>
                    <ListItemText>Название: {education.name}</ListItemText>
                    <ListItemText>Специальность: {education.speciality}</ListItemText>
                    <ListItemText>Уровень образования: {education.level}</ListItemText>
                    <ListItemText>Дата начала: {getDate(education.dateStart)}</ListItemText>
                    <ListItemText>Дата конца: {getDate(education.dateEnd)}</ListItemText>
                </ListItem>
            })
        }
        {
            (userData?.departmentalAwards || userData?.governmentAwards || userData?.stateAwards || userData?.presidentialAwards || userData?.participationInGrants) &&
            <Typography variant='h4'>Достижения и заслуги</Typography>
        }
        {
            userData?.departmentalAwards &&
            <Typography variant='subtitle1'>Государственные: {userData?.departmentalAwards}</Typography>
        }
        {
            userData?.governmentAwards &&
            <Typography variant='subtitle1'>Правительственные: {userData?.governmentAwards}</Typography>
        }
        {
            userData?.stateAwards && <Typography variant='subtitle1'>Ведомственные: {userData?.stateAwards}</Typography>
        }
        {
            userData?.presidentialAwards &&
            <Typography variant='subtitle1'>Президентские: {userData?.presidentialAwards}</Typography>
        }
        {
            userData?.participationInGrants &&
            <Typography variant='subtitle1'>Участие в грантах: {userData?.participationInGrants}</Typography>
        }
        {
            (userData?.agePeriodOfChildren || userData?.methodicalManual || userData?.methodologicalRecommendations || userData?.textbook || userData?.educationAndMethodicalComplex || userData?.anotherResearches) &&
            <Typography variant='h4'>Дополнительная информация</Typography>
        }
        {
            userData?.agePeriodOfChildren &&
            <Typography variant='subtitle1'>Я хотел бы принять участие в фундаментальном исследовании по проблемам
                изучения детей в периоде {userData?.agePeriodOfChildren}</Typography>
        }
        {
            (userData?.methodologicalRecommendations || userData?.methodicalManual || userData?.textbook || userData?.educationAndMethodicalComplex || userData?.anotherResearches) &&
            <Typography variant='h4'>Хотел бы стать разработчиком:</Typography>
        }
        {
            userData?.methodologicalRecommendations &&
            <Typography variant='subtitle1'>Методические рекомендации</Typography>
        }
        {
            userData?.methodologicalRecommendations && <ListItem sx={{
                border: '1px solid black',
                borderRadius: 3,
                p: 1,
                mb: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column'
            }} variant='subtitle1'>
                <ListItemText>Научная
                    область: {JSON.parse(userData?.methodologicalRecommendations).scientificField}</ListItemText>
                <ListItemText>Категория
                    обучающихся: {JSON.parse(userData?.methodologicalRecommendations).categoryOfStudents}</ListItemText>
                <ListItemText>Возраст: {JSON.parse(userData?.methodologicalRecommendations).age}</ListItemText>
            </ListItem>
        }
        {
            userData?.methodicalManual && <Typography variant='subtitle1'>Методическое пособие</Typography>
        }
        {
            userData?.methodicalManual && <ListItem sx={{
                border: '1px solid black',
                borderRadius: 3,
                p: 1,
                mb: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column'
            }} variant='subtitle1'>
                <ListItemText>Научная область: {JSON.parse(userData?.methodicalManual).scientificField}</ListItemText>
                <ListItemText>Категория
                    обучающихся: {JSON.parse(userData?.methodicalManual).categoryOfStudents}</ListItemText>
                <ListItemText>Возраст: {JSON.parse(userData?.methodicalManual).age}</ListItemText>
            </ListItem>
        }
        {
            userData?.textbook && <Typography variant='subtitle1'>Учебник</Typography>
        }
        {
            userData?.textbook && <ListItem sx={{
                border: '1px solid black',
                borderRadius: 3,
                p: 1,
                mb: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column'
            }} variant='subtitle1'>
                <ListItemText>Научная область: {JSON.parse(userData?.textbook).scientificField}</ListItemText>
                <ListItemText>Категория обучающихся: {JSON.parse(userData?.textbook).categoryOfStudents}</ListItemText>
                <ListItemText>Возраст: {JSON.parse(userData?.textbook).age}</ListItemText>
            </ListItem>
        }
        {
            userData?.educationAndMethodicalComplex &&
            <Typography variant='subtitle1'>Учебно-методический комплекс</Typography>
        }
        {
            userData?.educationAndMethodicalComplex && <ListItem sx={{
                border: '1px solid black',
                borderRadius: 3,
                p: 1,
                mb: 1,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column'
            }} variant='subtitle1'>
                <ListItemText>Научная
                    область: {JSON.parse(userData?.educationAndMethodicalComplex).scientificField}</ListItemText>
                <ListItemText>Категория
                    обучающихся: {JSON.parse(userData?.educationAndMethodicalComplex).categoryOfStudents}</ListItemText>
                <ListItemText>Возраст: {JSON.parse(userData?.educationAndMethodicalComplex).age}</ListItemText>
            </ListItem>
        }
        {
            userData?.anotherResearches && <Typography variant='subtitle1'>Другое</Typography>
        }
        {
            userData?.anotherResearches && JSON.parse(userData?.anotherResearches)?.map((recommendation, index) => {
                return <ListItem sx={{
                    border: '1px solid black',
                    borderRadius: 3,
                    p: 1,
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column'
                }} key={index} variant='subtitle1'>
                    <ListItemText>Название: {recommendation.name}</ListItemText>
                    <ListItemText>Научная область: {recommendation.scientificField}</ListItemText>
                    <ListItemText>Категория обучающихся: {recommendation.categoryOfStudents}</ListItemText>
                    <ListItemText>Возраст: {recommendation.age}</ListItemText>
                </ListItem>
            })
        }
    </Container>
}

export default UserPage