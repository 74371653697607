import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import thunkCreators from '../../../redux/thunks'
import Autocomplete from '../../common/Autocomplete/Autocomplete'
import searchActionCreators from '../../../redux/reducers/search/actions'

const SpecialityAutocomplete = (props) => {
  const dispatch = useDispatch()
  const specialities = useSelector((state) => state.appData.specialities)
  const value = useSelector((state) => state.search.speciality)

  const options = specialities
    .map((speciality) => {
      return speciality.name
    })
    .sort()

  const subjectSelectHandler = (_, value) => {
    dispatch(searchActionCreators.changeSearchField('speciality', value))
    dispatch(thunkCreators.filterGeography())
  }

  return (
    <Autocomplete
      sx={props.sx}
      label="Специальность"
      options={options}
      value={value}
      onChange={subjectSelectHandler}
    />
  )
}

export default SpecialityAutocomplete
