import React from "react";
import { useSelector } from "react-redux";
import Autocomplete from "../common/Autocomplete/Autocomplete";

const ScientificStatusSelect = (props) => {
  const { inputValue, onChange, value, onInputChange, name, required, sx, label, id } = props;

  const scientificStatuses = useSelector((state) => state.appData.scientificStatuses);

  const scientificStatusSelectOptions = scientificStatuses.map((status) => {
    return {
      key: status._id,
      value: status._id,
      text: status.name,
    };
  });

  const options = scientificStatusSelectOptions
      .map((status) => {
        return status.text
      })
      .sort()

  return (
    <Autocomplete
      label={label ? label : "Ученая степень*"}
      options={options}
      inputValue={inputValue}
      onInputChange={onInputChange}
      name={name}
      id={id}
      onChange={onChange}
      value={value}
      sx={sx}
      required={required}
      freeSolo
    />
  );
};

export default ScientificStatusSelect;
