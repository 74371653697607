import React, {useEffect, useState} from 'react'
import {grey} from '@mui/material/colors'
import {Box, Typography} from '@mui/material'
import Icon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'

const Notice = (props) => {

    const [startTransition, setStartTransition] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setStartTransition(true)
        }, 0)
    })

    if (props.jsx) return <Box
        sx={{
            position: 'relative',
            width: '250px',
            pt: 5,
            pb: 5,
            pl: 1,
            pr: 1,
            mb: 2,
            border: "1px solid #000",
            backgroundColor: grey[300],
            borderRadius: '5px',
            transition: 'opacity 2s',
            opacity: startTransition ? 1 : 0,
        }}
    >
        <Icon
            fontSize="10px"
            sx={{
                position: 'absolute',
                top: '10px',
                right: '5px',
                cursor: 'pointer',
            }}
            onClick={props.onDeleteButtonClick}
        />
        <Typography fontSize="14px">
            {props.jsx}
        </Typography>
    </Box>

    return (
        <Box
            sx={{
                position: 'relative',
                width: '250px',
                pt: 5,
                pb: 5,
                pl: 1,
                pr: 1,
                mb: 2,
                border: "1px solid #000",
                backgroundColor: grey[300],
                borderRadius: '5px',
                transition: 'opacity 2s',
                opacity: startTransition ? 1 : 0,
            }}
        >
            <Icon
                fontSize="10px"
                sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '5px',
                    cursor: 'pointer',
                }}
                onClick={props.onDeleteButtonClick}
            />
            <Typography fontSize="14px">
                {props.text}
            </Typography>
        </Box>
    )
}

Notice.propTypes = {
    text: PropTypes.string.isRequired,
    onDeleteButtonClick: PropTypes.func.isRequired,
}

export default Notice
