import { Button } from '@mui/material'
import React from 'react'

const FileUploadButton = (props) => {
  return (
    <Button
      variant={props.buttonVariant ? props.buttonVariant : 'contained'}
      component="label"
      sx={props.sx}
      color={props.color}
    >
      {props.children}
      <input
        onChange={props.onChange}
        style={{ display: 'none' }}
        type="file"
        accept={props.accept}
        required={props.required}
      />
    </Button>
  )
}

export default FileUploadButton
