import React, {createRef} from 'react'
import { useSelector } from 'react-redux'
import {Redirect, Route, useHistory} from 'react-router-dom'
import { routes } from '../../routes'

const routesToRouteElements = (routes) => {
  return routes.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      component={route.element}
      exact
    ></Route>
  ))
}

export const historyRef = createRef()

const AppRouter = () => {
  const authData = useSelector((state) => state.auth)
  const isAuth = !!authData.token
  const isAdmin = authData.role === 'ADMIN'

  const history = useHistory()
  historyRef.current = history

  const publicRoutes = routesToRouteElements(routes.public)
  const guestRoutes = routesToRouteElements(routes.guest)
  const adminRoutes = routesToRouteElements(routes.admin)
  const userRoutes = routesToRouteElements(routes.user)

  return (
    <>
      {publicRoutes}
      {isAuth ? userRoutes : null}
      {isAdmin ? adminRoutes : null}
      {!isAuth ? guestRoutes : null}
      <Redirect to="/" />
    </>
  )
}

export default AppRouter
