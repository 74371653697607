import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from '@mui/material'
import React from 'react'
import AddScientificStatusForm from './AddScientificStatusForm/AddScientificStatusForm'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ScientificStatusesList from './ScientificStatusesList/ScientificStatusesList'

const ScientificStatuses = () => {
  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ bgcolor: '#1976d2', color: '#fff' }}
        >
          <Typography fontWeight="bold" sx={{ textAlign: 'center' }}>
            Изменение научных статусов
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AddScientificStatusForm />
          <br />
          <ScientificStatusesList />
        </AccordionDetails>
      </Accordion>
    </Container>
  )
}

export default ScientificStatuses
