import React from "react";
import { useSelector } from "react-redux";
import Select from "../common/Select/Select";
import Autocomplete from "../common/Autocomplete/Autocomplete";

const SpecialitySelect = (props) => {
  const { inputValue, onInputChange, value, onChange, name, required, sx, label, id } = props;
  const specialities = useSelector((state) => state.appData.specialities);

  const specialitySelectOptions = specialities.map((speciality) => {
    return {
      key: speciality._id,
      value: speciality._id,
      text: speciality.name,
    };
  });

  const options = specialitySelectOptions
      .map((status) => {
        return status.text
      })
      .sort()

  return (
    <Autocomplete
      label={label ? label : "Специальность*"}
      options={options}
      inputValue={inputValue}
      onInputChange={onInputChange}
      name={name}
      sx={sx}
      value={value}
      onChange={onChange}
      required={required}
      freeSolo
    />
  );
};

export default SpecialitySelect;
