const objectToFormData = (object) => {
  const formData = new FormData()
  for (const key in object) {
    if (object[key] instanceof File) {
      formData.append(key, object[key], object[key].name)
    } else {
      formData.append(key, object[key])
    }
  }

  return formData
}

export default objectToFormData
