import axios from "axios";

const api = axios.create({
  baseURL: "/api/user",
});

const userAPI = {
  async addKeyword(keyword, userId, token) {
    return api.patch(
      `/${userId}/keyword/${keyword}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
  },
  async deleteKeyword(keyword, userId, token) {
    return api.delete(`/${userId}/keyword/${keyword}`, {
      headers: {
        Authorization: token,
      },
    });
  },
  async deleteUserById(userId, token) {
    return api.delete(`/${userId}`, {
      headers: {
        Authorization: token,
      },
    });
  },
  async getById(userId) {
    return api.get(`/getById/${userId}`);
  },
  async getAvailableScientists(userId) {
    return api.get(`/scientists/${userId}`)
  },
  async changeById(userChangeData, userId, token) {
    return api.patch(
      `/${userId}`,
        userChangeData,
      {
        headers: {
          Authorization: token,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
};

export default userAPI;
