import React from "react";
import { useSelector } from "react-redux";
import Autocomplete from "../common/Autocomplete/Autocomplete";

const AgesAutocomplete = (props) => {
    const { inputValue, onChange, value, onInputChange, name, required, sx, label, id } = props;

    const ages = useSelector((state) => state.appData.agePeriods);

    const agesSelectOptions = ages.map((status) => {
        return {
            key: status._id,
            value: status._id,
            text: status.name,
        };
    });

    const options = agesSelectOptions
        .map((status) => {
            return status.text
        })
        .sort()

    return (
        <Autocomplete
            label={label ? label : "Период"}
            options={options}
            inputValue={inputValue}
            onInputChange={onInputChange}
            name={name}
            id={id}
            onChange={onChange}
            value={value}
            sx={sx}
            required={required}
            freeSolo
        />
    );
};

export default AgesAutocomplete;
