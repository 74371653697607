import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from '@mui/material'
import React from 'react'

const Select = (props) => {
  const optionsElemnts = props.options.map((option, index) => {
    if (typeof option === 'object') {
      return (
        <MenuItem key={option.key ? option.key : index} value={option.value}>
          {option.text}
        </MenuItem>
      )
    }

    return (
      <MenuItem key={index} value={option}>
        {option}
      </MenuItem>
    )
  })

  return (
    <FormControl fullWidth sx={props.sx}>
      <InputLabel style={{ backgroundColor: '#fff' }} id={`${props.id}-label`}>
        {props.label}
      </InputLabel>

      <MUISelect
        labelId={`${props.id}-label`}
        id={props.id}
        name={props.name}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
      >
        {optionsElemnts}
      </MUISelect>
    </FormControl>
  )
}

export default Select
