export const geographyActionTypes = {
  SET_MAP_DISTRICTS: 'SET_MAP_DISTRICTS',
  SET_MAP_SUBJECTS: 'SET_MAP_SUBJECTS',
  SET_MAP_POINTS: 'SET_MAP_POINTS',
  DELETE_MAP_POINT: 'DELETE_MAP_POINT',
  ADD_MAP_POINT: 'ADD_MAP_POINT'
}

const geographyActionCreators = {
  setMapDistricts: (districts) => ({
    type: geographyActionTypes.SET_MAP_DISTRICTS,
    payload: {
      districts,
    },
  }),
  setMapPoints: (points) => ({
    type: geographyActionTypes.SET_MAP_POINTS,
    payload: {
      points,
    },
  }),
  setMapSubjects: (subjects) => ({
    type: geographyActionTypes.SET_MAP_SUBJECTS,
    payload: {
      subjects,
    },
  }),
  deleteMapPoint: (userId) => ({
    type: geographyActionTypes.DELETE_MAP_POINT,
    payload: {
      userId
    }
  }),
  addMapPoint: (point) => ({
    type: geographyActionTypes.ADD_MAP_POINT,
    payload: {
      point
    }
  })
}

export default geographyActionCreators
