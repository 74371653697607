import React from "react";
import { useSelector } from "react-redux";
import Autocomplete from "../common/Autocomplete/Autocomplete";

const ScientistsAutocomplete = (props) => {
    const { inputValue, onInputChange, value, onChange, name, required, sx, label, id, filterValue, isNotFreeSolo } = props;
    const scientists = useSelector((state) => state.appData.availableScientists);

    const filterValueText = filterValue.map(value => value.text)

    const scientistsSelectOptions = scientists.map((scientist) => {
        return {
            key: scientist._id,
            value: scientist._id,
            text: `${scientist.name} ${scientist.surname} ${scientist.parentName} ${scientist.email}`,
        };
    }).filter(interest => {
        return !Object.values(filterValueText).includes(interest.text)
    })

    return (
        <Autocomplete
            label={label ? label : "Ученые"}
            options={scientistsSelectOptions}
            getOptionLabel={option => option.text}
            inputValue={inputValue}
            onInputChange={onInputChange}
            name={name}
            sx={sx}
            value={value}
            onChange={onChange}
            required={required}
            freeSolo={!isNotFreeSolo}
        />
    );
};

export default ScientistsAutocomplete;
