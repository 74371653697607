import AdminPage from './pages/AdminPage/AdminPage'
import AuthorizationPage from './pages/AuthorizationPage/AuthorizationPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage'
import MainPage from './pages/MainPage/MainPage'
import RegistrationPage from './pages/RegistrationPage/RegistrationPage'
import SettingsPage from './pages/SettingsPage/SettingsPage'
import UserPage from "./pages/UserPage/UserPage";

const routePaths = {
  public: {
    MAIN: '/',
    USER: '/user/:id'
  },
  guest: {
    AUTHORIZATION: '/authorization',
    REGISTRATION: '/registration',
    FORGOT_PASSWORD: '/FORGOT_PASSWORD',
  },
  user: {
    SETTINGS: '/settings',
  },
  admin: {
    ADMIN: '/admin',
  },
}

export const routes = {
  public: [
    {
      path: routePaths.public.MAIN,
      element: MainPage,
    },
    {
      path: routePaths.public.USER,
      element: UserPage
    }
  ],
  user: [
    {
      path: routePaths.user.SETTINGS,
      element: SettingsPage,
    },
  ],
  admin: [
    {
      path: routePaths.admin.ADMIN,
      element: AdminPage,
    },
  ],
  guest: [
    {
      path: routePaths.guest.AUTHORIZATION,
      element: AuthorizationPage,
    },
    {
      path: routePaths.guest.REGISTRATION,
      element: RegistrationPage,
    },
    {
      path: routePaths.guest.FORGOT_PASSWORD,
      element: ForgotPasswordPage,
    },
  ],
}

export default routePaths
