import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import searchActionCreators from '../../../redux/reducers/search/actions'
import thunkCreators from '../../../redux/thunks'
import Input from '../../common/Input/Input'

const KeywordsInput = (props) => {
  const dispatch = useDispatch()
  const value = useSelector((state) => state.search.keywords)
  const handleInputChange = (event) => {
    dispatch(
      searchActionCreators.changeSearchField('keywords', event.target.value),
    )
    dispatch(thunkCreators.filterGeography())
  }

  return (
    <Input
      label="Ключевые слова и фразы (через точку с запятой)"
      sx={props.sx}
      value={value}
      onChange={handleInputChange}
    />
  )
}

export default KeywordsInput
