import { Container, Box, Typography } from '@mui/material'
import React from 'react'
import routePaths from '../../routes'
import { Link } from 'react-router-dom'
import IKPLogo2 from '../../img/IKPLogo2.png'
import EagleLogo from '../../img/EagleLogo.png'
import YoutubeIcon from '../../img/YoutubeIcon.png'
import VKIcon from '../../img/VKIcon.png'

const Footer = () => {
  const FooterTitle = ({ children }) => {
    return (
      <Typography
        whiteSpace="nowrap"
        color="#fff"
        fontSize="20px"
        component="h5"
        sx={{ mb: 2 }}
      >
        {children}
      </Typography>
    )
  }

  const FooterText = ({ children }) => {
    return (
      <Typography color="#fff" component="p" sx={{ mb: 2 }}>
        {children}
      </Typography>
    )
  }

  const FooterLink = ({ children, href }) => {
    return (
      <a
        style={{
          color: 'inherit',
          fontSize: 'inherit',
          textDecoration: 'none',
          whiteSpace: 'nowrap',
        }}
        href={href}
      >
        {children}
      </a>
    )
  }

  const LogoLinks = () => {
    return (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          display: 'flex',
        }}
      >
        <Link to={routePaths.public.MAIN}>
          <Box
            sx={{
              width: {
                xs: '140px',
                md: '150px',
              },
            }}
          >
            <img style={{ width: '100%' }} src={IKPLogo2} alt="IKPLogo2" />
          </Box>
        </Link>
        <Link to={routePaths.public.MAIN}>
          <Box
            sx={{
              width: {
                xs: '140px',
                md: '150px',
              },
            }}
          >
            <img style={{ width: '100%' }} src={EagleLogo} alt="EagleLogo" />
          </Box>
        </Link>
      </Box>
    )
  }

  const FooterCol = ({ children }) => {
    return <Box sx={{ flex: '1 1 33%', mb: 1 }}>{children}</Box>
  }

  return (
    <footer
      style={{
        backgroundColor: '#1976d2',
        padding: '40px 0 20px',
        marginTop: '20px',
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '20px',
          flexWrap: {
            xs: 'wrap',
            md: 'nowrap',
          },
        }}
      >
        <FooterCol>
          <LogoLinks />
        </FooterCol>
        <FooterCol>
          <FooterTitle>ФГБНУ "ИКП РАО"</FooterTitle>
          <FooterText>
            119121, Москва, <br /> Погодинская ул., д.8, корп. 1
          </FooterText>
          <FooterTitle>
            <FooterLink href="tel:+74992450452">+7 (499) 245-04-52</FooterLink>
          </FooterTitle>
          <Box>
            <FooterLink href="https://www.youtube.com/channel/UCxXbLOHLPjIoz8E3ccTGrsg">
              <img src={YoutubeIcon} />
            </FooterLink>
            {' '}
            <FooterLink href="https://m.vk.com/ikp_rao">
              <img src={VKIcon} />
            </FooterLink>
          </Box>
        </FooterCol>
        <FooterCol>
          <FooterText>
            © 2021 Федеральное государственное бюджетное научное учреждение
            «Институт коррекционной педагогики Российской академии образования»
          </FooterText>
        </FooterCol>
      </Container>
    </footer>
  )
}

export default Footer
