import axios from 'axios'

const api = axios.create({
    baseURL: 'api/education',
})

const educationAPI = {
    async create(name, token) {
        return api.post(
            '/',
            {
                name,
            },
            {
                headers: {
                    Authorization: token,
                },
            },
        )
    },
    async getAll() {
        return api.get('/')
    },
    async deleteById(educationId, token) {
        return api.delete(`/${educationId}`, {
            headers: {
                Authorization: token,
            },
        })
    },
}

export default educationAPI
