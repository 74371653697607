import { FormControl, TextField } from '@mui/material'
import React from 'react'

const Input = (props) => {
  return (
    <FormControl
      fullWidth={props.fullWidth ? props.fullWidth : true}
      sx={props.sx ? props.sx : { mb: 2 }}
    >
      <TextField
        disabled={props.disabled}
        required={props.required}
        label={props.label}
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        type={props.type}
        autoComplete='off'
        inputProps={{
          minLength: props.minLength,
          maxLength: props.maxLength,
        }}
        InputLabelProps={{
            shrink: true,
        }}
      />
    </FormControl>
  )
}

export default Input
