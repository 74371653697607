import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Avatar, Box, Button, Container, TextField} from "@mui/material";
import routes from "../../routes";
import Title from "../../components/common/Title/Title";
import {useDispatch, useSelector} from "react-redux";
import Input from "../../components/common/Input/Input";
import thunkCreators from "../../redux/thunks";
import FileUploadButton from "../../components/common/FileUploadButton/FileUploadButton";
import ScientificStatusSelect from "../../components/ScientificStatusSelect/ScientificStatusSelect";
import SpecialitySelect from "../../components/SpecialitySelect/SpecialitySelect";
import Datepicker from "../../components/common/Datepicker/Datepicker"
import dayjs from "dayjs";
import EducationSelect from "../../components/EducationSelect/EducationSelect";
import AcademicTitleSelect from "../../components/AcademicTitleSelect/AcademicTitleSelect";
import UniversityAutocomplete from "../../components/UniversityAutocomplete/UniversityAutocomplete";


const RegistrationPage = () => {
    const dispatch = useDispatch();

    const [formFields, setFormFields] = useState({
        email: "",
        password: "",
        name: "",
        parentName: "",
        surname: "",
        speciality: "",
        scientificStatus: "",
        avatar: null,
        dateOfBirth: dayjs(),
        education: '',
        academicTitle: '',
        university: '',
        honoryTitle: ''
    });

    const handleInputChange = (event) => {
        let value

        if (!event) return

        if (event?.$d) {
            value = event?.$d

            setFormFields({
                ...formFields,
                dateOfBirth: value,
            });
        } else {
            value = event.target.name === "email" ? event.target.value.toLowerCase() : event.target.value;
            setFormFields({
                ...formFields,
                [event.target.name]: value,
            });
        }
    };

    const handleAutocompleteChange = (event, newInputValue, name) => {
        setFormFields((prevState) => ({
            ...prevState,
            [name]: newInputValue
        }))
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        dispatch(thunkCreators.submitRegistration(formFields));
    };

    const handleFileInputChange = (event) => {
        if (event.target.files[0].size > 1048576) {
            alert("Слишком большой размер фотографии");
            return;
        }

        setFormFields({
            ...formFields,
            avatar: event.target.files[0],
        });
    };

    const createAvatarSrc = () => {
        if (formFields.avatar) {
            return URL.createObjectURL(formFields.avatar);
        }
    };

    return (
        <form onSubmit={handleFormSubmit}>
            <Container maxWidth="sm" sx={{mt: 10, minHeight: "calc(100vh - 450px)"}}>
                <Title>Регистрация</Title>

                <Input label="Email" name="email" onChange={handleInputChange} value={formFields.email} required
                       type="email"/>

                <Input label="Пароль" name="password" onChange={handleInputChange} value={formFields.password} required
                       type="password" minLength="8"/>

                <Input label="Фамилия" name="surname" onChange={handleInputChange} value={formFields.surname} required/>

                <Input label="Имя" name="name" value={formFields.name} onChange={handleInputChange} required/>

                <Input label="Отчество" name="parentName" value={formFields.parentName} onChange={handleInputChange}
                       required/>

                <Datepicker label="Дата рождения*" value={formFields.dateOfBirth} onChange={handleInputChange} required
                            renderInput={(params) => <TextField sx={{mb: 2}}
                                                                fullWidth={true} {...params} />}></Datepicker>

                <ScientificStatusSelect
                    inputValue={formFields.scientificStatus}
                    name="scientificStatus"
                    sx={{mb: 2}}
                    required
                    onInputChange={(event, newInputValue) => {
                        handleAutocompleteChange(event, newInputValue, 'scientificStatus')
                    }}
                />

                <SpecialitySelect onInputChange={(event, newInputValue) => {
                    handleAutocompleteChange(event, newInputValue, 'speciality')
                }} inputValue={formFields.speciality} name="speciality" sx={{mb: 2}} required/>

                <EducationSelect onChange={handleInputChange} value={formFields.education} name='education'
                                 sx={{mb: 2}} required/>

                <AcademicTitleSelect onChange={handleInputChange} value={formFields.academicTitle} name="academicTitle" sx={{mb:2}} required />

                <UniversityAutocomplete onInputChange={(event, newInputValue) => {
                    handleAutocompleteChange(event, newInputValue, 'university')
                }} inputValue={formFields.university} name="university" sx={{mb: 2}} required/>

                <Input label="Почетное звание" name="honoryTitle" value={formFields.honoryTitle} onChange={handleInputChange}/>

                <Box sx={{display: "flex", gap: "20px", alignItems: "center", mb: 2}}>
                    <Avatar sx={{width: 100, height: 100, border: "1px solid #c4c4c4"}} alt="avatar"
                            src={createAvatarSrc()}/>

                    <FileUploadButton
                        onChange={handleFileInputChange}
                        value={formFields.avatar}
                        accept=".jpg, .jpeg, .png"
                        sx={{display: "flex", height: "36.5px"}}
                        color="success"
                    >
                        Добавить фото
                    </FileUploadButton>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                        flexWrap: "wrap",
                    }}
                >
                    <Button variant="contained" type="submit">
                        Зарегистрироваться
                    </Button>

                    <Link to={routes.guest.AUTHORIZATION} style={{textDecoration: "none"}}>
                        <Button sx={{textTransform: "none"}}>Уже зарегистрированы?</Button>
                    </Link>
                </Box>
            </Container>
        </form>
    );
};

export default RegistrationPage;
