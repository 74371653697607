import { Box } from '@mui/system'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import notificationsActionCreators from '../../redux/reducers/notifications/actions'
import Message from './Notice/Notice'

const Notifications = () => {
  const dispatch = useDispatch()
  const messages = useSelector((state) => state.notifications.messages)

  const handleDeleteMessageButtonClick = (messageId) => {
    dispatch(notificationsActionCreators.deleteNotification(messageId))
  }

  const messageElements = messages.map((message) => {
    return (
      <Message
        text={message.text}
        key={message.id}
        jsx={message.jsx}
        onDeleteButtonClick={() => handleDeleteMessageButtonClick(message.id)}
      />
    )
  })

  return (
    <Box
      sx={{
        position: 'fixed',
        right: '10px',
        bottom: '10px   ',
      }}
    >
      {messageElements}
    </Box>
  )
}

export default Notifications
