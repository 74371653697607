import { Box, Button, Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import searchActionCreators from '../../../redux/reducers/search/actions'
import thunkCreators from '../../../redux/thunks'
import Input from '../../common/Input/Input'
import Subtitle from '../../common/Subtitle/Subtitle'
import DistrictSelect from '../../search/DistrictSelect/DistrictSelect'
import SubjectAutocomplete from '../../search/SubjectAutocomplete/SubjectAutocomplete'
import RuSubjects from '../MapRuSubjects/MapRuSubjects'
import css from './SettingsPageMap.module.scss'

const SettingsPageMap = (props) => {
  const dispatch = useDispatch()
  const [subjectId, setSubjectId] = useState('')
  const pointGeometry = useSelector((state) => state.search.geometry)
  const districts = useSelector((state) => state.geography.districts)
  const authData = useSelector((state) => state.auth)
  const userPoint = useSelector((state) => state.geography.points).filter(point => {
    return point?.additionalInfo?.userId === authData.userId
  })

  const { userId, token } = authData

  useEffect(() => {
    dispatch(searchActionCreators.resetSearchForm())
    dispatch(thunkCreators.filterGeography())
  }, [dispatch])

  const isSubmitButtonDisabled = !pointGeometry || pointGeometry.length !== 2

  const isDeletePointButtonDisabled = userPoint.length <= 0

  const mapState = {
    center: pointGeometry ? pointGeometry : [63, 100],
    zoom: 3,
  }

  const deletePoint = () => {
    dispatch(thunkCreators.deletePoint(userId, token))
  }

  const handleSubjectClick = (event, subjectData) => {
    const geometry = event.get('coords')
    setSubjectId(subjectData.id)
    const district = districts.find((district) => {
      return district.subjects.includes(subjectData.id)
    })
    dispatch(
      searchActionCreators.changeSearchField('subject', subjectData.name),
      searchActionCreators.changeSearchField('district', district.name),
    )
    dispatch(searchActionCreators.changeSearchField('geometry', geometry))
    dispatch(thunkCreators.filterGeography())
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()

    dispatch(
      thunkCreators.addUserPoint(userId, token, {
        geometry: pointGeometry,
        subjectId,
      }),
    )
  }

  return (
    <Box props={props.sx}>
      <Subtitle>Изменить метку</Subtitle>
      <Container maxWidth="xl" className={css.container} sx={{ mb: 3 }}>
        <form onSubmit={handleFormSubmit} className={css.form}>
          <DistrictSelect sx={{ mb: 2 }} />
          <SubjectAutocomplete sx={{ mb: 2 }} />
          <Typography>Укажите точку на карте мышью. Масштабируйте с помощью колесика мыши.</Typography>
          <Box className={css.geometry}>
            <Input disabled value={pointGeometry ? pointGeometry[0] : ''} />
            <Input disabled value={pointGeometry ? pointGeometry[1] : ''} />
          </Box>
          <Box>
            <Button
                disabled={isSubmitButtonDisabled}
                variant="contained"
                type="submit"
                sx={{mr: 2}}
            >
              подтвердить
            </Button>
            <Button color='error' variant="contained" disabled={isDeletePointButtonDisabled} onClick={deletePoint}>
              Удалить свою точку
            </Button>
          </Box>
        </form>
        <Box className={css.mapWrapper}>
          <YMaps>
            <Map style={{ width: '100%', height: '348px' }} state={mapState}>
              <RuSubjects onSubjectClick={handleSubjectClick} />
              {!pointGeometry ? null : <Placemark geometry={pointGeometry} />}
            </Map>
          </YMaps>
        </Box>
      </Container>
    </Box>
  )
}

export default SettingsPageMap
