import axios from 'axios'

const api = axios.create({
  baseURL: 'api/point',
})

const pointAPI = {
  async user(userId, token, { geometry, subjectId }) {
    return api.post(
      `/${userId}`,
      { geometry, subjectId },
      {
        headers: {
          Authorization: token,
        },
      },
    )
  },
  async getAll() {
    return api.get('/')
  },
  async deletePoint(userId, token) {
    return api.delete(`/${userId}`, {
      headers: {
        Authorization: token
      }
    })
  }
}

export default pointAPI
