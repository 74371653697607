import {Avatar, Typography, Link, Button} from '@mui/material'
import {Box} from '@mui/system'
import React from 'react'
import PropTypes from 'prop-types'
import Keywords from '../../../../Keywords/Keywords'
import {useHistory} from "react-router-dom";

const UserBaloonContent = (props) => {
    const avatar = props.avatar ? `/api/files/${props.userId}/${props.avatar}` : undefined
    const fullName = `${props.surname} ${props.name} ${props.parentName}`

    return (
        <Box minWidth={350} textAlign="left">
            <Box>
                <Avatar
                    src={avatar}
                    sx={{width: 150, height: 150, border: '1px solid #c4c4c4'}}
                    alt='Remy Sharp'
                />
            </Box>

            <Typography variant="h5">{fullName}</Typography>
            <Typography variant="subtitle1">{props.district}</Typography>
            <Typography variant="subtitle1">{props.subject}</Typography>
            <Box>
                <Link href={`mailto:${props.email}`} component="a" variant="subtitle1">
                    {props.email}
                </Link>
            </Box>
            {
                props.scientificStatus &&
                <Typography variant="subtitle1">Ученая степень: {props.scientificStatus}</Typography>
            }
            {
                props.speciality && <Typography variant="subtitle1">Специальность: {props.speciality}</Typography>
            }
            {
                props.education && <Typography variant="subtitle1">Образование: {props.education}</Typography>
            }
            {
                props.university && <Typography variant="subtitle1">Университет: {props.university}</Typography>
            }
            {
                props.academicTitle && <Typography variant="subtitle1">Ученое звание: {props.academicTitle}</Typography>
            }
            {
                props.honoryTitle && <Typography variant="subtitle1">Почетное звание: {props.honoryTitle}</Typography>
            }

            <Typography variant="subtitle2">Ключевые слова:</Typography>
            <Keywords words={props.keywords} removeDeleteButtons/>
        </Box>
    )
}

UserBaloonContent.propTypes = {
    subject: PropTypes.string,
    district: PropTypes.string,
    userId: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    parentName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    speciality: PropTypes.string.isRequired,
    scientificStatus: PropTypes.string.isRequired,
    keywords: PropTypes.arrayOf(PropTypes.string),
    avatar: PropTypes.string,
    education: PropTypes.string
}

export default UserBaloonContent
