export const appDataActionTypes = {
    SET_SPECIALITIES: 'SET_SPECIALITIES',
    SET_SCIENTIFIC_STATUSES: 'SET_SCIENTIFIC_STATUSES',
    SET_POINTS: 'SET_POINTS',
    SET_EDUCATION: 'SET_EDUCATION',
    SET_ACADEMIC_TITLES: 'SET_ACADEMIC_TITLES',
    SET_UNIVERSITIES: 'SET_UNIVERSITIES',
    SET_LANGUAGES: 'SET_LANGUAGES',
    SET_LANGUAGE_LEVELS: 'SET_LANGUAGE_LEVELS',
    SET_SCIENTIFIC_INTERESTS: 'SET_SCIENTIFIC_INTERESTS',
    SET_AGE_PERIODS: 'SET_AGE_PERIODS',
    SET_AVAILABLE_SCIENTISTS: 'SET_AVAILABLE_SCIENTISTS'
}

const appDataActionCreators = {
    setEducation: (education) => ({
        type: appDataActionTypes.SET_EDUCATION,
        payload: {
            education
        }
    }),
    setSpecialities: (specialities) => ({
        type: appDataActionTypes.SET_SPECIALITIES,
        payload: {
            specialities,
        },
    }),
    setScientificStatuses: (scientificStatuses) => ({
        type: appDataActionTypes.SET_SCIENTIFIC_STATUSES,
        payload: {
            scientificStatuses,
        },
    }),
    setPoints: (points) => ({
        type: appDataActionTypes.SET_POINTS,
        payload: {
            points,
        },
    }),
    setAcademicTitles: (academicTitles) => ({
        type: appDataActionTypes.SET_ACADEMIC_TITLES,
        payload: {
            academicTitles
        }
    }),
    setUniversities: (universities) => ({
        type: appDataActionTypes.SET_UNIVERSITIES,
        payload: {
          universities
        }
    }),
    setLanguages: (languages) => ({
        type: appDataActionTypes.SET_LANGUAGES,
        payload: {
            languages
        }
    }),
    setLanguageLevels: (languageLevels) => ({
        type: appDataActionTypes.SET_LANGUAGE_LEVELS,
        payload: {
            languageLevels
        }
    }),
    setScientificInterests: (scientificInterests) => ({
        type: appDataActionTypes.SET_SCIENTIFIC_INTERESTS,
        payload: {
            scientificInterests
        }
    }),
    setAgePeriods: (agePeriods) => ({
        type: appDataActionTypes.SET_AGE_PERIODS,
        payload: {
            agePeriods
        }
    }),
    setAvailableScientists: (scientists) => ({
        type: appDataActionTypes.SET_AVAILABLE_SCIENTISTS,
        payload: {
            scientists
        }
    })
}

export default appDataActionCreators
