import {DatePicker} from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers'
import 'dayjs/locale/ru'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const Datepicker = (props) => {
    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
        <DatePicker label={props.label} value={props.value} onChange={props.onChange} required={props.required} renderInput={props.renderInput} disabled={props.disabled}></DatePicker>
    </LocalizationProvider>
}

export default Datepicker