import React from 'react'
import {
  AppBar,
  Avatar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Box } from '@mui/system'
import IKPLogo from '../../img/IKPLogo.png'
import EagleLogo from '../../img/EagleLogoHeader.png'
import routePaths from '../../routes'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import thunkCreators from '../../redux/thunks'

const createPageLinks = (isAuth, isAdmin, userId) => {
  const authorizedPages = [
    {
      name: 'Настройки профиля',
      path: routePaths.user.SETTINGS,
    },
  ]

  const guestPages = [
    {
      name: 'Войти',
      path: routePaths.guest.AUTHORIZATION,
    },
  ]

  const adminPages = [
    {
      name: 'Кабинет администратора',
      path: routePaths.admin.ADMIN,
    },
  ]

  let pages = [
    {
      name: 'Главная',
      path: routePaths.public.MAIN,
    },
    {
      name: 'Профиль',
      path: `/user/${userId}`
    }
  ]

  if (isAuth) {
    pages = [...pages, ...authorizedPages]
    if (isAdmin) {
      pages = [...pages, ...adminPages]
    }
  } else {
    pages = [...pages, ...guestPages]
  }

  return pages
}

const createSettingLinks = (isAdmin) => {
  const settings = [
    {
      name: 'Настройки',
      path: routePaths.user.SETTINGS,
    },
  ]

  if (isAdmin) {
    settings.push({
      name: 'Кабинет администратора',
      path: routePaths.admin.ADMIN,
    })
  }

  return settings
}

const Header = () => {
  const dispatch = useDispatch()
  const authData = useSelector((state) => state.auth)

  const isAdmin = authData.role === 'ADMIN'
  const isAuth = !!authData.token

  const pages = createPageLinks(isAuth, isAdmin, authData.userId)
  const settings = createSettingLinks(isAdmin)

  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogoutClick = () => {
    dispatch(thunkCreators.logout())
  }

  const linkStyle = {
    textDecoration: 'none',
    color: 'black',
  }
  const logoLink = (
    <Link to={routePaths.public.MAIN} style={linkStyle}>
      <img src={IKPLogo} alt="IKPLogo" />
      <img className='hide-on-400' src={EagleLogo} alt="EagleLogo" />
    </Link>
  )

  const userName = authData.name
    ? authData.name[0].toUpperCase() + authData.name.slice(1)
    : 'Загрузка...'

  const profileMenu = (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Открыть меню">
        <IconButton
          onClick={handleOpenUserMenu}
          sx={{ p: 0, flexDirection: 'column' }}
        >
          <Avatar alt="Sharp" src={`/api/files/${authData.userId}/${authData.avatar}`} />
          <Typography fontSize="11px" color="#fff">
            {userName}
          </Typography>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem
            key={setting.path}
            onClick={handleCloseUserMenu}
            sx={{ pl: 0, pr: 0 }}
          >
            <Link
              to={setting.path}
              style={{ ...linkStyle, display: 'block', minWidth: '100%' }}
            >
              <Typography sx={{ pr: 2, pl: 2 }}>{setting.name}</Typography>
            </Link>
          </MenuItem>
        ))}
        <MenuItem onClick={handleLogoutClick} sx={{ pl: 0, pr: 0 }}>
          <Typography sx={{ pr: 2, pl: 2, textAlign: 'left' }}>
            Выйти
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )

  return (
    <AppBar position="static" sx={{ pt: 1, pb: 1 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ mr: 5, display: { xs: 'none', md: 'flex' } }}>
            {logoLink}
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.path}
                  onClick={handleCloseNavMenu}
                  sx={{ pl: 0, pr: 0 }}
                >
                  <Link
                    to={page.path}
                    style={{ ...linkStyle, display: 'block', minWidth: '100%' }}
                  >
                    <Typography sx={{ color: 'black', pr: 2, pl: 2 }}>
                      {page.name}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            {logoLink}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Box key={page.path}>
                <Link to={page.path} style={linkStyle}>
                  <Typography
                    sx={{
                      my: 2,
                      mr: 3,

                      color: 'white',
                      display: 'block',
                    }}
                  >
                    {page.name}
                  </Typography>
                </Link>
              </Box>
            ))}
          </Box>

          {authData.token ? profileMenu : null}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header
