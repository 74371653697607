import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import thunkCreators from '../../../redux/thunks'
import Autocomplete from '../../common/Autocomplete/Autocomplete'
import searchActionCreators from '../../../redux/reducers/search/actions'

const ScientificStatusAutocomplete = (props) => {
  const dispatch = useDispatch()
  const scientificStatuses = useSelector(
    (state) => state.appData.scientificStatuses,
  )
  const value = useSelector((state) => state.search.scientificStatus)

  const options = scientificStatuses
    .map((status) => {
      return status.name
    })
    .sort()

  const subjectSelectHandler = (_, value) => {
    dispatch(searchActionCreators.changeSearchField('scientificStatus', value))
    dispatch(thunkCreators.filterGeography())
  }

  return (
    <Autocomplete
      sx={props.sx}
      label="Ученая степень"
      options={options}
      value={value}
      onChange={subjectSelectHandler}
    />
  )
}

export default ScientificStatusAutocomplete
