import {
    Avatar,
    Box,
    Button, Checkbox,
    Container,
    Fab,
    FormControlLabel,
    List,
    ListItem, ListItemButton, ListItemText,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import thunkCreators from "../../../redux/thunks";
import Input from "../../common/Input/Input";
import Subtitle from "../../common/Subtitle/Subtitle";
import ScientificStatusSelect from "../../ScientificStatusSelect/ScientificStatusSelect";
import SpecialitySelect from "../../SpecialitySelect/SpecialitySelect";
import Datepicker from "../../common/Datepicker/Datepicker";
import api from "../../../api";
import EducationSelect from "../../EducationSelect/EducationSelect";
import FileUploadButton from "../../common/FileUploadButton/FileUploadButton";
import AcademicTitleSelect from "../../AcademicTitleSelect/AcademicTitleSelect";
import UniversityAutocomplete from "../../UniversityAutocomplete/UniversityAutocomplete";
import LanguageSelect from "../../LanguageSelect/LanguageSelect";
import LanguageLevelSelect from "../../LanguageLevelSelect/LanguageLevelSelect";
import ScientistsAutocomplete from "../../ScientistsAutocomplete/ScientistsAutocomplete";
import AddIcon from '@mui/icons-material/Add'
import AgesAutocomplete from "../../AgesAutocomplete/AgesAutocomplete";
import ScientificInterestAutocomplete from "../../ScientificInterestAutocomplete/ScientificInterestAutocomplete";
import {Delete} from "@mui/icons-material";
import {Link} from 'react-router-dom'

const ChangeProfileForm = (props) => {
        const dispatch = useDispatch();
        const {userId, token} = useSelector((state) => state.auth);
        const [currentScientificInterest, setCurrentScientificInterest] = useState('')
        const [name, setName] = useState('')
        const [parentName, setParentName] = useState('')
        const [surname, setSurname] = useState('')
        const [speciality, setSpeciality] = useState('')
        const [scientificStatus, setScientificStatus] = useState('')
        const [education, setEducation] = useState('')
        const [dateOfBirth, setDateOfBirth] = useState('')
        const [avatar, setAvatar] = useState('')
        const [academicTitle, setAcademicTitle] = useState('')
        const [university, setUniversity] = useState('')
        const [honoryTitle, setHonoryTitle] = useState('')
        const [phoneNumber, setPhoneNumber] = useState('')
        const [email, setEmail] = useState('')
        const [mainLanguage, setMainLanguage] = useState('')
        const [mainLanguageLevel, setMainLanguageLevel] = useState('')
        const [secondLanguage, setSecondLanguage] = useState('')
        const [secondLanguageLevel, setSecondLanguageLevel] = useState('')
        const [spinCode, setSpinCode] = useState('')
        const [publications, setPublications] = useState('')
        const [participationInProjects, setParticipationInProjects] = useState('')
        const [participationInGrantsPublications, setParticipationInGrantsPublications] = useState('')
        const [participationInConferences, setParticiptionInConferences] = useState('')
        const [stateAwards, setStateAwards] = useState('')
        const [departmentalAwards, setDepartmentalAwards] = useState('')
        const [governmentAwards, setGovernmentAwards] = useState('')
        const [presidentialAwards, setPresidentialAwards] = useState('')
        const [participationInGrants, setParticipationInGrants] = useState('')
        const [agePeriodOfChildren, setAgePeriodOfChildren] = useState('')
        const [scientificInterests, setScientificInterests] = useState([])
        const [scientificCooperation, setScientificCooperation] = useState([])
        const [workExperience, setWorkExperience] = useState([])
        const [schools, setSchools] = useState([])
        const [secondaryEducation, setSecondaryEducation] = useState([])
        const [higherEducation, setHigherEducation] = useState([])
        const [methodologicalRecommendations, setMethodologicalRecommendations] = useState({
            scientificField: '',
            categoryOfStudents: '',
            age: ''
        })
        const [methodicalManual, setMethodicalManual] = useState({
            scientificField: '',
            categoryOfStudents: '',
            age: ''
        })
        const [textBook, setTextBook] = useState({
            scientificField: '',
            categoryOfStudents: '',
            age: ''
        })
        const [educationAndMethodicalComplex, setEducationAndMethodicalComplex] = useState({
            scientificField: '',
            categoryOfStudents: '',
            age: ''
        })
        const [anotherResearches, setAnotherResearches] = useState([])

        const [currentScientist, setCurrentScientist] = useState({
            text: ''
        })

        useEffect(() => {
            api.user.getById(userId).then(res => {
                const data = res.data
                setName(data.name)
                setSurname(data.surname)
                setParentName(data.parentName)
                setSpeciality(data.speciality)
                setScientificStatus(data.scientificStatus)
                setEducation(data.education)
                setDateOfBirth(data.dateOfBirth)
                setAcademicTitle(data.academicTitle)
                setUniversity(data.university || '')
                setHonoryTitle(data.honoryTitle)
                setPhoneNumber(data.phoneNumber)
                setEmail(data.email)
                setMainLanguage(data.mainLanguage)
                setMainLanguageLevel(data.mainLanguageLevel)
                setSecondLanguage(data.secondLanguage)
                setSecondLanguageLevel(data.secondLanguageLevel)
                setSpinCode(data.spinCode)
                setPublications(data.publications)
                setParticipationInProjects(data.participationInProjects)
                setParticipationInGrantsPublications(data.participationInGrantsPublications)
                setParticiptionInConferences(data.participationInConferences)
                setStateAwards(data.stateAwards)
                setDepartmentalAwards(data.departmentalAwards)
                setGovernmentAwards(data.governmentAwards)
                setPresidentialAwards(data.presidentialAwards)
                setParticipationInGrants(data.participationInGrants)
                setAgePeriodOfChildren(data.agePeriodOfChildren || [])
                setScientificInterests(data.scientificInterests ? JSON.parse(data.scientificInterests) : [])
                setScientificCooperation(data.scientificCooperation ? JSON.parse(data.scientificCooperation) : [])
                setWorkExperience(data.workExperience ? JSON.parse(data.workExperience) : [])
                setSchools(data.schools ? JSON.parse(data.schools) : [])
                setSecondaryEducation(data.secondaryEducation ? JSON.parse(data.secondaryEducation) : [])
                setHigherEducation(data.higherEducation ? JSON.parse(data.higherEducation) : [])
                setMethodologicalRecommendations(data.methodologicalRecommendations ? JSON.parse(data.methodologicalRecommendations) : {})
                setEducationAndMethodicalComplex(data.educationAndMethodicalComplex ? JSON.parse(data.educationAndMethodicalComplex) : {})
                setTextBook(data.textbook ? JSON.parse(data.textbook) : {})
                setMethodicalManual(data.methodicalManual ? JSON.parse(data.methodicalManual) : {})
                setAnotherResearches(data.anotherResearches ? JSON.parse(data.anotherResearches) : [])
            })
        }, [])

        const addScientificCooperation = () => {
            setScientificCooperation((prevState) => [
                ...prevState, currentScientist
            ])
            setCurrentScientist({
                text: ''
            })
        }

        const addWorkExperience = () => {
            setWorkExperience((prevState) => [
                ...prevState, {post: '', job: '', dateStart: null, dateEnd: null, isCurrentlyWorking: false}
            ])
        }

        const addSchool = () => {
            setSchools((prevState) => [
                ...prevState, {name: '', dateStart: null, dateEnd: null}
            ])
        }

        const addSecondaryEducation = () => {
            setSecondaryEducation((prevState) => [
                ...prevState, {name: '', speciality: '', dateStart: null, dateEnd: null}
            ])
        }

        const addHigherEducation = () => {
            setHigherEducation((prevState) => [
                ...prevState, {name: '', speciality: '', educationLevel: '', dateStart: null, dateEnd: null}
            ])
        }

        const addAnotherResearch = () => {
            setAnotherResearches((prevState) => [
                ...prevState, {name: '', scientificField: '', categoryOfStudents: '', age: ''}
            ])
        }

        const switchIsCurrentlyWorking = (index) => {
            setWorkExperience((prevState) => {
                return [
                    ...prevState.slice(0, index),
                    {...prevState[index], isCurrentlyWorking: !prevState[index].isCurrentlyWorking, dateEnd: null},
                    ...prevState.slice(index + 1)
                ]
            })
        }
        const handleFormSubmit = (event) => {
            event.preventDefault();

            const formFields = {
                name,
                parentName,
                surname,
                speciality,
                scientificStatus,
                education,
                dateOfBirth,
                avatar,
                academicTitle,
                university,
                honoryTitle,
                phoneNumber,
                email,
                mainLanguage,
                mainLanguageLevel,
                secondLanguage,
                secondLanguageLevel,
                spinCode,
                publications,
                participationInProjects,
                participationInGrantsPublications,
                participationInConferences,
                stateAwards,
                departmentalAwards,
                governmentAwards,
                presidentialAwards,
                participationInGrants,
                agePeriodOfChildren,
            }

            formFields['scientificInterests'] = JSON.stringify(scientificInterests)
            formFields['scientificCooperation'] = JSON.stringify(scientificCooperation)
            formFields['workExperience'] = JSON.stringify(workExperience)
            formFields['secondaryEducation'] = JSON.stringify(secondaryEducation)
            formFields['schools'] = JSON.stringify(schools)
            formFields['higherEducation'] = JSON.stringify(higherEducation)
            formFields['schools'] = JSON.stringify(schools)


            formFields['methodologicalRecommendations'] = JSON.stringify(methodologicalRecommendations)
            formFields['methodicalManual'] = JSON.stringify(methodicalManual)
            formFields['textbook'] = JSON.stringify(textBook)
            formFields['educationAndMethodicalComplex'] = JSON.stringify(educationAndMethodicalComplex)
            formFields['anotherResearches'] = JSON.stringify(anotherResearches)

            scientificInterests.forEach((interest) => {
                formFields[`interestFile${interest.name}`] = interest.file
            })

            console.log(formFields)

            dispatch(thunkCreators.changeUserProfile(formFields, userId, token));
        };

        const handleFileInputChange = (event) => {
            if (event.target.files[0].size > 1048576) {
                alert("Слишком большой размер фотографии");
                return;
            }

            setAvatar(
                event.target.files[0],
            );
        };

        const handleInterestUpload = (event, index, name) => {
            if (event.target.files[0].size > 1048576) {
                alert("Слишком большой размер файла");
                return;
            }

            const updatedInterest = {
                name,
                file: event.target.files[0]
            }

            setScientificInterests((prevArray) => {
                return [...prevArray.slice(0, index), updatedInterest, ...prevArray.slice(index + 1)]
            })
        }

        const addScientificInterest = () => {
            setScientificInterests(oldState => [...oldState, {name: currentScientificInterest, file: null}])

            setCurrentScientificInterest('')
        }

        const createAvatarSrc = () => {
            if (avatar) {
                return URL.createObjectURL(avatar);
            }
        };

        const deleteInterest = (index) => {
            setScientificInterests((prevState) => [
                ...prevState.slice(0, index),
                ...prevState.slice(index + 1)
            ])
        }

        return (
            <form onSubmit={handleFormSubmit} style={{width: "100%"}}>
                <Container maxWidth="md" sx={props.sx}>
                    <Subtitle>Изменить данные пользователя</Subtitle>
                    <Typography variant='h6' textAlign='center' sx={{mb: 2}}>Данные, введенные при
                        регистрации</Typography>
                    <Input label="Фамилия" name="surname" onChange={(e) => {
                        setSurname(e.target.value)
                    }} value={surname} required/>
                    <Input label="Имя" name="name" value={name} onChange={(e) => {
                        setName(e.target.value)
                    }} required/>
                    <Input label="Отчество" name="parentName" value={parentName} onChange={(e) => {
                        setParentName(e.target.value)
                    }}
                           required/>
                    <Datepicker label="Дата рождения*" value={dateOfBirth} onChange={(value) => {
                        setDateOfBirth(value)
                    }} required
                                renderInput={(params) => <TextField sx={{mb: 2}}
                                                                    fullWidth={true} {...params} />}></Datepicker>
                    <ScientificStatusSelect
                        inputValue={scientificStatus}
                        name="scientificStatus"
                        sx={{mb: 2}}
                        required
                        onInputChange={(_, newInputValue) =>
                            setScientificStatus(newInputValue)
                        }
                    />

                    <SpecialitySelect onInputChange={(_, newInputValue) =>
                        setSpeciality(newInputValue)
                    } inputValue={speciality} name="speciality" sx={{mb: 2}} required/>

                    <EducationSelect onChange={(e) => setEducation(e.target.value)} value={education} name='education'
                                     sx={{mb: 2}} required/>
                    <AcademicTitleSelect onChange={(e) => setAcademicTitle(e.target.value)} value={academicTitle}
                                         name="academicTitle"
                                         sx={{mb: 2}} required/>

                    <UniversityAutocomplete onInputChange={(_, newInputValue) =>
                        setUniversity(newInputValue)
                    } inputValue={university} name="university" sx={{mb: 2}} required/>

                    <Input label="Почетное звание" name="honoryTitle" value={honoryTitle}
                           onChange={(e) => setHonoryTitle(e.target.value)}/>

                    <Box sx={{display: "flex", gap: "20px", alignItems: "center", mb: 2}}>
                        <Avatar sx={{width: 100, height: 100, border: "1px solid #c4c4c4"}} alt="avatar"
                                src={createAvatarSrc()}/>

                        <FileUploadButton
                            onChange={handleFileInputChange}
                            value={avatar}
                            accept=".jpg, .jpeg, .png"
                            sx={{display: "flex", height: "36.5px"}}
                            color="success"
                        >
                            Добавить фото
                        </FileUploadButton>
                    </Box>

                    <Typography variant='h6' textAlign='center' sx={{mb: 2}}>Основное</Typography>

                    <Input label='Телефон' name='phoneNumber' type='tel' value={phoneNumber}
                           onChange={(e) => setPhoneNumber(e.target.value)}/>
                    <Input label='Адрес электронной почты' name='email' type='email' value={email}
                           onChange={(e) => setEmail(e.target.value)} required/>
                    <LanguageSelect label="Основной язык" name='mainLanguage' value={mainLanguage}
                                    onChange={(e) => setMainLanguage(e.target.value)} sx={{mb: 2}}/>
                    {
                        mainLanguage &&
                        <LanguageLevelSelect name='mainLanguageLevel' label="Уровень владения основным языком"
                                             onChange={(e) => setMainLanguageLevel(e.target.value)}
                                             value={mainLanguageLevel}
                                             sx={{mb: 2}}/>
                    }
                    <LanguageSelect label="Дополнительный язык" name='secondLanguage' sx={{mb: 2}}
                                    value={secondLanguage} onChange={(e) => setSecondLanguage(e.target.value)}/>
                    {secondLanguage &&
                        <LanguageLevelSelect name='secondLanguageLevel' value={secondLanguageLevel}
                                             onChange={(e) => setSecondLanguageLevel(e.target.value)}
                                             label="Уровень владения дополнительным языком"
                                             sx={{mb: 2}}/>}
                    <Input value={spinCode} name='spinCode' onChange={(e) => setSpinCode(e.target.value)}
                           label='SPIN-код автора (eLibrary.ru)'/>

                    <Typography variant='h6' textAlign='center' sx={{mb: 2}}>Научные интересы</Typography>
                    <Typography variant='subtitle1' sx={{mb: 1}}>Влияют на рекомендации мероприятий и содержание писем,
                        которые вы получаете.</Typography>
                    <ScientificInterestAutocomplete inputValue={currentScientificInterest}
                                                    onInputChange={(_, newInputValue) => setCurrentScientificInterest(newInputValue)}
                                                    sx={{mb: 2}}
                                                    filterValues={scientificInterests}

                    />
                    <Box sx={{display: 'flex', gap: 2, alignItems: 'center', mb: 2}}>
                        <Fab color='primary' onClick={addScientificInterest}
                             disabled={!currentScientificInterest}><AddIcon/></Fab>
                        <Typography variant="subtitle2">Добавить</Typography>
                    </Box>

                    <List sx={{mb: 2}}>
                        {
                            scientificInterests.map((interest, index) => {
                                return <ListItem sx={{
                                    border: '1px solid black',
                                    borderRadius: 3,
                                    p: 1,
                                    mb: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                                                 key={interest.name}
                                                 variant='subtitle1'><ListItemText
                                    sx={{maxWidth: '50%'}}>{interest.name}</ListItemText>
                                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                                        {interest.file &&
                                            <Typography variant='subtitle2'>Файл загружен</Typography>
                                        }
                                        <FileUploadButton
                                            value={scientificInterests[index].file}
                                            onChange={(event) => handleInterestUpload(event, index, interest.name)}
                                            accept=".jpg, .jpeg, .png, .pdf, .docx, .odt">Добавить
                                            файл...</FileUploadButton>
                                        <ListItemButton onClick={() => deleteInterest(index)}><Delete/></ListItemButton>
                                    </Box>
                                </ListItem>
                            })
                        }
                    </List>


                    <Typography variant='h6' textAlign='center' sx={{mb: 2}}>Научное сотрудничество</Typography>
                    <Typography variant='subtitle1' sx={{mb: 1}}>Выберите учёных, с которыми Вы взаимодействуете в
                        рамках
                        своей работы и научных интересов.</Typography>
                    <ScientistsAutocomplete sx={{mb: 2}}
                                            value={currentScientist}
                                            onChange={(event, value) => setCurrentScientist(value)}
                                            filterValue={scientificCooperation}
                                            isNotFreeSolo={true}/>

                    <Box sx={{display: 'flex', gap: 2, alignItems: 'center', mb: 2}}>
                        <Fab color='primary' onClick={() => addScientificCooperation()}
                             disabled={!currentScientist}><AddIcon/></Fab>
                        <Typography variant="subtitle2">Добавить</Typography>
                    </Box>

                    {
                        scientificCooperation.map((scientist, index) => {
                            return (
                                <Box sx={{
                                    border: '1px solid black',
                                    borderRadius: 3,
                                    p: 1,
                                    display: 'flex',
                                    gap: 1,
                                    mb: 1,
                                    alignItems: 'center',
                                }} key={scientist.key}>
                                    <ListItemText variant='subtitle1'
                                                  sx={{minWidth: '80%'}}>{scientist.text}</ListItemText>
                                    <ListItemButton
                                        onClick={() => setScientificCooperation(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])}><Delete/></ListItemButton>
                                </Box>)
                        })
                    }

                    <Typography variant='h6' textAlign='center' sx={{mb: 2}}>Публикационная активность</Typography>
                    <Typography variant='subtitle1' sx={{mb: 1}}>Опишите опыт Вашей публикационной активности за
                        последние 5
                        лет.</Typography>
                    <TextField multiline minRows={3} fullWidth value={publications}
                               onChange={(e) => setPublications(e.target.value)}
                               label='Публикации (Scopus, Web of science, ВАК, РИНЦ)'
                               sx={{mb: 2}}/>
                    <TextField multiline minRows={3} value={participationInProjects}
                               onChange={(e) => setParticipationInProjects(e.target.value)} fullWidth
                               label='Участие в проектах' sx={{mb: 2}}/>
                    <TextField multiline minRows={3} value={participationInGrantsPublications}
                               onChange={(e) => setParticipationInGrantsPublications(e.target.value)} fullWidth
                               label='Участие в грантах' sx={{mb: 2}}/>
                    <TextField multiline minRows={3} value={participationInConferences}
                               onChange={(e) => setParticiptionInConferences(e.target.value)} fullWidth
                               label='Участие в Международных/Всероссийских конференциях'
                               sx={{mb: 2}}/>

                    <Typography variant='h6' textAlign='center' sx={{mb: 2}}>Опыт работы</Typography>
                    {
                        workExperience.map((work, index) => {
                            return <div key={index}>
                                <Typography variant='subtitle2'
                                            sx={{textAlign: 'center', mb: 2}}>Работа {index + 1}</Typography>
                                <Input label='Должность' value={work.post}
                                       onChange={(e) => setWorkExperience(prevState => [...prevState.slice(0, index),
                                           {...prevState[index], post: e.target.value},
                                           ...prevState.slice(index + 1)])}/>
                                <Input label='Место работы (организация)' value={work.job}
                                       onChange={(e) => setWorkExperience(prevState => [...prevState.slice(0, index),
                                           {...prevState[index], job: e.target.value},
                                           ...prevState.slice(index + 1)])}/>
                                <Datepicker label='Дата начала работы' value={work.dateStart}
                                            onChange={(newValue) => setWorkExperience(prevState => [...prevState.slice(0, index),
                                                {...prevState[index], dateStart: newValue},
                                                ...prevState.slice(index + 1)])}
                                            renderInput={(params) => <TextField sx={{mb: 2}}
                                                                                fullWidth={true} {...params} />}/>
                                <Datepicker label='Дата окончания работы' value={work.dateEnd}
                                            disabled={work.isCurrentlyWorking}
                                            onChange={(newValue) => setWorkExperience(prevState => [...prevState.slice(0, index),
                                                {...prevState[index], dateEnd: newValue},
                                                ...prevState.slice(index + 1)])}
                                            renderInput={(params) => <TextField sx={{mb: 2}}
                                                                                fullWidth={true} {...params} />}/>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2}}>
                                    <FormControlLabel label='Работаю по настоящее время'
                                                      control={<Checkbox value={work.isCurrentlyWorking}
                                                                         checked={work.isCurrentlyWorking}
                                                                         onChange={() => switchIsCurrentlyWorking(index)}/>}/>
                                    <Button onClick={() => {
                                        setWorkExperience(prevState => [
                                            ...prevState.slice(0, index),
                                            ...prevState.slice(index + 1)
                                        ])
                                    }
                                    }><Delete/></Button>
                                </Box>
                            </div>
                        })
                    }

                    <Box sx={{display: 'flex', gap: 2, alignItems: 'center', mb: 2}}>
                        <Fab color="primary" onClick={addWorkExperience}><AddIcon/></Fab>
                        <Typography variant='subtitle1'>Добавить место работы</Typography>
                    </Box>

                    <Typography variant='h6' textAlign='center' sx={{mb: 2}}>Образование</Typography>
                    <Typography variant='subtitle1' sx={{mb: 1}}>Укажите, где вы учились, так вам будет легче найти
                        коллег и
                        единомышленников для решения ваших задач.</Typography>
                    <Box sx={{display: 'flex', gap: 2, alignItems: 'center', mb: 2}}>
                        <Fab color="primary" onClick={() => addSchool()}><AddIcon/></Fab>
                        <Typography variant='subtitle1'>Добавить школу</Typography>
                    </Box>
                    {
                        schools.map((school, index) => {
                            return <Box key={school.index}>
                                <Typography variant='subtitle2'
                                            sx={{textAlign: 'center', mb: 2}}>Школа {index + 1}</Typography>
                                <Input label='Название школы' value={school.name}
                                       onChange={(e) => setSchools(prevState => [...prevState.slice(0, index),
                                           {...prevState[index], name: e.target.value},
                                           ...prevState.slice(index + 1)])}/>
                                <Datepicker label='Дата начала обучения' value={school.dateStart}
                                            onChange={(newValue) => setSchools(prevState => [...prevState.slice(0, index),
                                                {...prevState[index], dateStart: newValue},
                                                ...prevState.slice(index + 1)])}
                                            renderInput={(params) => <TextField sx={{mb: 2}}
                                                                                fullWidth={true} {...params} />}/>
                                <Datepicker label='Дата конца обучения' value={school.dateEnd}
                                            onChange={(newValue) => setSchools(prevState => [...prevState.slice(0, index),
                                                {...prevState[index], dateEnd: newValue},
                                                ...prevState.slice(index + 1)])}
                                            renderInput={(params) => <TextField sx={{mb: 2}}
                                                                                fullWidth={true} {...params} />}/>
                                <Button sx={{mb: 2}} onClick={() => {
                                    setSchools(prevState => [
                                        ...prevState.slice(0, index),
                                        ...prevState.slice(index + 1)
                                    ])
                                }
                                }><Delete/></Button>
                            </Box>
                        })
                    }
                    <Box sx={{display: 'flex', gap: 2, alignItems: 'center', mb: 2}}>
                        <Fab color="primary" onClick={() => addSecondaryEducation()}><AddIcon/></Fab>
                        <Typography variant='subtitle1'>Добавить среднее образование</Typography>
                    </Box>
                    {
                        secondaryEducation.map((secondary, index) => {
                            return <Box>
                                <Typography variant='subtitle2' sx={{textAlign: 'center', mb: 2}}>Среднее
                                    образование {index + 1}</Typography>
                                <Input label='Название учебного заведения' value={secondary.name} sx={{mb: 2}}
                                       onChange={(e) => setSecondaryEducation(prevState => [...prevState.slice(0, index),
                                           {...prevState[index], name: e.target.value},
                                           ...prevState.slice(index + 1)])}/>
                                <Input label='Специальность' value={secondary.speciality} sx={{mb: 2}}
                                       onChange={(e) => setSecondaryEducation(prevState => [...prevState.slice(0, index),
                                           {...prevState[index], speciality: e.target.value},
                                           ...prevState.slice(index + 1)])}/>
                                <Datepicker label='Дата начала обучения' sx={{mb: 2}} value={secondary.dateStart}
                                            onChange={(newValue) => setSecondaryEducation(prevState => [...prevState.slice(0, index),
                                                {...prevState[index], dateStart: newValue},
                                                ...prevState.slice(index + 1)])}
                                            renderInput={(params) => <TextField sx={{mb: 2}}
                                                                                fullWidth={true} {...params} />}/>
                                <Datepicker label='Дата конца обучения' sx={{mb: 2}} value={secondary.dateEnd}
                                            onChange={(newValue) => setSecondaryEducation(prevState => [...prevState.slice(0, index),
                                                {...prevState[index], dateEnd: newValue},
                                                ...prevState.slice(index + 1)])}
                                            renderInput={(params) => <TextField sx={{mb: 2}}
                                                                                fullWidth={true} {...params} />}/>
                                <Button sx={{mb: 2}} onClick={() => {
                                    setSecondaryEducation(prevState => [
                                        ...prevState.slice(0, index),
                                        ...prevState.slice(index + 1)
                                    ])
                                }
                                }><Delete/></Button>
                            </Box>
                        })
                    }
                    <Box sx={{display: 'flex', gap: 2, alignItems: 'center', mb: 2}}>
                        <Fab color="primary" onClick={() => addHigherEducation()}><AddIcon/></Fab>
                        <Typography variant='subtitle1'>Добавить высшее образование</Typography>
                    </Box>
                    {
                        higherEducation.map((higher, index) => {
                            return <Box>
                                <Typography variant='subtitle2' sx={{textAlign: 'center', mb: 2}}>Высшее
                                    образование {index + 1}</Typography>
                                <Input label='Название учебного заведения' value={higher.name} sx={{mb: 2}}
                                       onChange={(e) => setHigherEducation(prevState => [...prevState.slice(0, index),
                                           {...prevState[index], name: e.target.value},
                                           ...prevState.slice(index + 1)])}/>
                                <Input label='Специальность' value={higher.speciality} sx={{mb: 2}}
                                       onChange={(e) => setHigherEducation(prevState => [...prevState.slice(0, index),
                                           {...prevState[index], speciality: e.target.value},
                                           ...prevState.slice(index + 1)])}/>
                                <EducationSelect
                                    onChange={(e) => setHigherEducation(prevState => [...prevState.slice(0, index),
                                        {...prevState[index], educationLevel: e.target.value},
                                        ...prevState.slice(index + 1)])} value={higher.educationLevel} name='education'
                                    sx={{mb: 2}} required/>
                                <Datepicker label='Дата начала обучения' sx={{mb: 2}} value={higherEducation.dateStart}
                                            onChange={(newValue) => setHigherEducation(prevState => [...prevState.slice(0, index),
                                                {...prevState[index], dateStart: newValue},
                                                ...prevState.slice(index + 1)])}
                                            renderInput={(params) => <TextField sx={{mb: 2}}
                                                                                fullWidth={true} {...params} />}/>
                                <Datepicker label='Дата конца обучения' sx={{mb: 2}} value={higherEducation.dateEnd}
                                            onChange={(newValue) => setHigherEducation(prevState => [...prevState.slice(0, index),
                                                {...prevState[index], dateEnd: newValue},
                                                ...prevState.slice(index + 1)])}
                                            renderInput={(params) => <TextField sx={{mb: 2}}
                                                                                fullWidth={true} {...params} />}/>
                                <Button sx={{mb: 2}} onClick={() => {
                                    setHigherEducation(prevState => [
                                        ...prevState.slice(0, index),
                                        ...prevState.slice(index + 1)
                                    ])
                                }
                                }><Delete/></Button>
                            </Box>
                        })
                    }
                    <Typography variant='h6' textAlign='center' sx={{mb: 2}}>Достижения и заслуги</Typography>
                    <Typography variant='subtitle1' textAlign='center' sx={{mb: 2}}>Награды</Typography>
                    <Input label='Ведомественные' value={stateAwards} onChange={(e) => setStateAwards(e.target.value)}
                           sx={{mb: 2}}/>
                    <Input label='Государственные' value={departmentalAwards}
                           onChange={e => setDepartmentalAwards(e.target.value)}
                           sx={{mb: 2}}/>
                    <Typography variant='subtitle1' textAlign='center' sx={{mb: 2}}>Премии</Typography>
                    <Input label='Правительства' value={governmentAwards}
                           onChange={(e) => setGovernmentAwards(e.target.value)} sx={{mb: 2}}/>
                    <Input label='Президентские' value={presidentialAwards}
                           onChange={(e) => setPresidentialAwards(e.target.value)} sx={{mb: 2}}/>
                    <Typography variant='subtitle1' textAlign='center' sx={{mb: 2}}>Участие в грантах</Typography>
                    <TextField multiline minRows={3} value={participationInGrants}
                               onChange={(e) => setParticipationInGrants(e.target.value)} fullWidth
                               label='Участие в грантах' sx={{mb: 2}}/>

                    <Typography variant='h6' textAlign='center' sx={{mb: 2}}>Дополнительная информация</Typography>
                    <Typography variant='subtitle1' textAlign='center' sx={{mb: 2}}>Укажите, пожалуйста, в какой области
                        развития науки Вы хотели бы принять участие. </Typography>
                    <Typography variant="subtitle2" textAlign='center' sx={{mb: 2}}>В фундаментальном исследовании по
                        проблемам изучения детей в разных возрастных периодах</Typography>
                    <AgesAutocomplete name='ages' inputValue={agePeriodOfChildren}
                                      onInputChange={(_, newInputValue) => setAgePeriodOfChildren(newInputValue)}
                                      sx={{mb: 2}}/>
                    <Typography variant="subtitle2" textAlign='center' sx={{mb: 2}}>В прикладном исследовании (стать
                        разработчиком)</Typography>
                    <Typography vairant="subtitle2" sx={{mb: 2}}>Методические рекомендации</Typography>
                    <Input label='Научная область' value={methodologicalRecommendations.scientificField}
                           onChange={e => setMethodologicalRecommendations((prevState) => ({
                               ...
                                   prevState, scientificField: e.target.value
                           }))}/>
                    <Input label='Категория обучающихся' value={methodologicalRecommendations.categoryOfStudents}
                           onChange={e => setMethodologicalRecommendations((prevState) => ({
                               ...
                                   prevState, categoryOfStudents: e.target.value
                           }))}/>
                    <Input label='Возраст' value={methodologicalRecommendations.age}
                           onChange={e => setMethodologicalRecommendations((prevState) => ({
                               ...
                                   prevState, age: e.target.value
                           }))}/>
                    <Typography vairant="subtitle2" sx={{mb: 2}}>Методическое пособие</Typography>
                    <Input label='Научная область' value={methodicalManual.scientificField}
                           onChange={e => setMethodicalManual((prevState) => ({
                               ...
                                   prevState, scientificField: e.target.value
                           }))}/>
                    <Input label='Категория обучающихся' value={methodicalManual.categoryOfStudents}
                           onChange={e => setMethodicalManual((prevState) => ({
                               ...
                                   prevState, categoryOfStudents: e.target.value
                           }))}/>
                    <Input label='Возраст' value={methodicalManual.age}
                           onChange={e => setMethodicalManual((prevState) => ({
                               ...
                                   prevState, age: e.target.value
                           }))}/>
                    <Typography vairant="subtitle2" sx={{mb: 2}}>Учебник</Typography>
                    <Input label='Научная область' value={textBook.scientificField}
                           onChange={e => setTextBook((prevState) => ({
                               ...
                                   prevState, scientificField: e.target.value
                           }))}/>
                    <Input label='Категория обучающихся' value={textBook.categoryOfStudents}
                           onChange={e => setTextBook((prevState) => ({
                               ...
                                   prevState, categoryOfStudents: e.target.value
                           }))}/>
                    <Input label='Возраст' value={textBook.age}
                           onChange={e => setTextBook((prevState) => ({
                               ...
                                   prevState, age: e.target.value
                           }))}/>
                    <Typography vairant="subtitle2" sx={{mb: 2}}>Учебно-методический комплекс</Typography>
                    <Input label='Научная область' value={educationAndMethodicalComplex.scientificField}
                           onChange={e => setEducationAndMethodicalComplex((prevState) => ({
                               ...
                                   prevState, scientificField: e.target.value
                           }))}/>
                    <Input label='Категория обучающихся' value={educationAndMethodicalComplex.categoryOfStudents}
                           onChange={e => setEducationAndMethodicalComplex((prevState) => ({
                               ...
                                   prevState, categoryOfStudents: e.target.value
                           }))}/>
                    <Input label='Возраст' value={educationAndMethodicalComplex.age}
                           onChange={e => setEducationAndMethodicalComplex((prevState) => ({
                               ...
                                   prevState, age: e.target.value
                           }))}/>
                    <Box sx={{display: 'flex', gap: 2, alignItems: 'center', mb: 2}}>
                        <Typography vairant="subtitle2">Другое</Typography>
                        <Fab color="primary" onClick={addAnotherResearch}><AddIcon/></Fab>
                    </Box>

                    {
                        anotherResearches.map((anotherResearch, index) => {
                            return <Box>
                                <Typography variant='subtitle2' sx={{textAlign: 'center', mb: 2}}>Прикладное
                                    исследование {index + 1}</Typography>
                                <Input label='Название' value={anotherResearch.name} sx={{mb: 2}}
                                       onChange={(e) => setAnotherResearches(prevState => [...prevState.slice(0, index),
                                           {...prevState[index], name: e.target.value},
                                           ...prevState.slice(index + 1)])}/>
                                <Input label='Научная область' value={anotherResearch.scientificField} sx={{mb: 2}}
                                       onChange={(e) => setAnotherResearches(prevState => [...prevState.slice(0, index),
                                           {...prevState[index], scientificField: e.target.value},
                                           ...prevState.slice(index + 1)])}/>
                                <Input label='Категория обучающихся' value={anotherResearch.categoryOfStudents} sx={{mb: 2}}
                                       onChange={(e) => setAnotherResearches(prevState => [...prevState.slice(0, index),
                                           {...prevState[index], categoryOfStudents: e.target.value},
                                           ...prevState.slice(index + 1)])}/>
                                <Input label='Возраст' value={anotherResearch.age} sx={{mb: 2}}
                                       onChange={(e) => setAnotherResearches(prevState => [...prevState.slice(0, index),
                                           {...prevState[index], age: e.target.value},
                                           ...prevState.slice(index + 1)])}/>
                                <Button sx={{mb: 2}} onClick={() => {
                                    setAnotherResearches(prevState => [
                                        ...prevState.slice(0, index),
                                        ...prevState.slice(index + 1)
                                    ])
                                }
                                }><Delete/></Button>
                            </Box>
                        })
                    }

                    <Box>
                        <Button variant="contained" type="submit">
                            Изменить
                        </Button>
                    </Box>
                </Container>
            </form>
        );
    }
;

export default ChangeProfileForm;
