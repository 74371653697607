import axios from 'axios'

const api = axios.create({
  baseURL: 'api/speciality',
})

const specialityAPI = {
  async create(name, token) {
    return api.post(
      '/',
      {
        name,
      },
      {
        headers: {
          Authorization: token,
        },
      },
    )
  },
  async getAll() {
    return api.get('/')
  },
  async deleteById(specialityId, token) {
    return api.delete(`/${specialityId}`, {
      headers: {
        Authorization: token,
      },
    })
  },
}

export default specialityAPI
