import deepcopy from '../../../utils/deepcopy'
import { searchActionTypes } from './actions'

const initalState = {
  district: '',
  subject: null,
  speciality: null,
  scientificStatus: null,
  keywords: '',
  geometry: null,
}

const searchReducer = (state = deepcopy(initalState), action) => {
  switch (action.type) {
    case searchActionTypes.RESET_SEARCH_FORM:
      return deepcopy(initalState)

    case searchActionTypes.CHANGE_SEARCH_FIELD_VALUE_BY_NAME:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      }

    default:
      return state
  }
}

export default searchReducer
