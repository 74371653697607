import {Container} from "@mui/material";
import React from "react";
import Title from "../../components/common/Title/Title";
import SettingsPageMap from "../../components/maps/SettingsPageMap/SettingsPageMap";
import KeywordsForm from "../../components/settings/KeywordsForm/KeywordsForm";
import DeleteAccount from "../../components/settings/DeleteAccount/DeleteAccount";
import ChangeProfileForm from "../../components/settings/ChangeProfileForm/ChangeProfileForm";

const SettingsPage = () => {
    return (
        <Container maxWidth="xl">
            <Title sx={{mt: 6}}>Настройки</Title>
            <ChangeProfileForm sx={{mb: 2}}/>
            <SettingsPageMap sx={{mb: 2}}/>
            <KeywordsForm sx={{mb: 2}}/>
            <DeleteAccount sx={{mb: 2}}/>
        </Container>
    );
};

export default SettingsPage;
