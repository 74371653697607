import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Container } from "@mui/material";
import routePaths from "../../routes";
import Input from "../../components/common/Input/Input";
import { useDispatch } from "react-redux";
import thunkCreators from "../../redux/thunks";
import Subtitle from "../../components/common/Subtitle/Subtitle";

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const handleFormSubmit = (event) => {
    event.preventDefault();
    dispatch(thunkCreators.forgotPassword(email));
  };

  const handleInputChange = (event) => {
    const value = event.target.name === "email" ? event.target.value.toLowerCase() : event.target.value;

    setEmail(value);
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <Container maxWidth="sm" sx={{ mt: 10, minHeight: "calc(100vh - 450px)" }}>
        <Subtitle>Восстановление пароля</Subtitle>
        <Input label="Email" name="email" onChange={handleInputChange} value={email} required type="email" />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
            flexWrap: "wrap",
          }}
        >
          <Button variant="contained" type="submit">
            Восстановить
          </Button>
          <Link to={routePaths.guest.AUTHORIZATION} style={{ textDecoration: "none" }}>
            <Button sx={{ textTransform: "none" }}>Войти</Button>
          </Link>

          <Link to={routePaths.guest.REGISTRATION} style={{ textDecoration: "none" }}>
            <Button sx={{ textTransform: "none" }}>Зарегистрироваться</Button>
          </Link>
        </Box>
      </Container>
    </form>
  );
};

export default ForgotPasswordPage;

// const AuthorizationPage = () => {
//   const dispatch = useDispatch()
//   const [formFields, setFormFields] = useState({
//     email: '',
//     password: '',
//   })

//   const handleFormSubmit = (event) => {
//     event.preventDefault()
//     dispatch(thunkCreators.login(formFields))
//   }

//   const handleInputChange = (event) => {
//     const value =
//       event.target.name === 'email'
//         ? event.target.value.toLowerCase()
//         : event.target.value

//     setFormFields({
//       ...formFields,
//       [event.target.name]: value,
//     })
//   }

//   return (
//     <form onSubmit={handleFormSubmit}>
//       <Container maxWidth="sm" sx={{ mt: 10 }}>
//         <Title>Авторизация</Title>
//         <Input
//           label="Email"
//           name="email"
//           onChange={handleInputChange}
//           value={formFields.email}
//           required
//           type="email"
//         />

//         <Input
//           label="Пароль"
//           name="password"
//           onChange={handleInputChange}
//           required
//           type="password"
//           minLength="8"
//         />
//         <Box
//           sx={{
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             gap: '15px',
//             flexWrap: 'wrap',
//           }}
//         >
//           <Button variant="contained" type="submit">
//             Войти
//           </Button>
//           <Link
//             to={routePaths.guest.REGISTRATION}
//             style={{ textDecoration: 'none' }}
//           >
//             <Button sx={{ textTransform: 'none' }}>Нет аккаунта?</Button>
//           </Link>
//           <Link
//             to={routePaths.guest.FORGOT_PASSWORD}
//             style={{ textDecoration: 'none' }}
//           >
//             <Button sx={{ textTransform: 'none' }}>Забыли пароль?</Button>
//           </Link>
//         </Box>
//       </Container>
//     </form>
//   )
// }

// export default AuthorizationPage
