import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from '@mui/material'
import React from 'react'
import AddSpecialtityForm from './AddSpecialityForm/AddSpecialtityForm'
import SpecialitiesList from './SpecialitiesList/SpecialitiesList'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const Specialities = () => {
  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ bgcolor: '#1976d2', color: '#fff' }}
        >
          <Typography fontWeight="bold" sx={{ textAlign: 'center' }}>
            Изменение специальностей
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AddSpecialtityForm />
          <br />
          <SpecialitiesList />
        </AccordionDetails>
      </Accordion>
    </Container>
  )
}

export default Specialities
