import store from '.'
import api from '../api'
import objectToFormData from '../utils/objectToFormData'
import appDataActionCreators from './reducers/appData/actions'
import geographyActionCreators from './reducers/geography/actions'
import searchActionCreators from './reducers/search/actions'
import subjectsJson from '../data/subjects.json'
import districtsJson from '../data/federalDistricts.json'
import deepcopy from '../utils/deepcopy'
import notificationsActionCreators from './reducers/notifications/actions'
import authActionCreators from './reducers/auth/actions'

const thunkCreators = {
    // search points
    filterGeography: () => (dispatch) => {
        const state = store.getState()
        const searchQuery = state.search

        let points = deepcopy(state.appData.points)
        let subjects = deepcopy(subjectsJson)
        let districts = deepcopy(districtsJson)

        if (searchQuery.subject) {
            const subject = subjects.find((subject) => {
                return subject.geometry.properties.hintContent === searchQuery.subject
            })

            const district = districts.find((district) => {
                return district.subjects.includes(subject.geometry.id)
            })

            if (district) {
                districts = [district]
                dispatch(
                    searchActionCreators.changeSearchField('district', district.name),
                )
            }

            if (subject) {
                subjects = [subject]
            }
        } else if (searchQuery.district) {
            const district = districts.find((district) => {
                return district.name === searchQuery.district
            })

            subjects = subjects.filter((subject) => {
                return district.subjects.includes(subject.geometry.id)
            })
        }

        const subjectIds = subjects.map((subject) => {
            return subject.geometry.id
        })

        points = points.filter((point) => {
            return subjectIds.includes(point.subjectId)
        })

        if (searchQuery.speciality) {
            const specialities = state.appData.specialities
            const currentSpeciality = specialities.find((speciality) => {
                return speciality.name === searchQuery.speciality
            }).name

            points = points.filter((point) => {
                if (point.type === 'USER') {
                    return point.additionalInfo.speciality === currentSpeciality
                }
                return true
            })
        }

        if (searchQuery.scientificStatus) {
            const scientificStatuses = state.appData.scientificStatuses
            const currentScientificStatus = scientificStatuses.find((status) => {
                return status.name === searchQuery.scientificStatus
            }).name

            points = points.filter((point) => {
                if (point.type === 'USER') {
                    return (
                        point.additionalInfo.scientificStatus ===
                        currentScientificStatus
                    )
                }
                return true
            })
        }

        if (searchQuery.keywords) {
            const keywordsArray = searchQuery.keywords
                .split('; ')
                .filter((word) => word !== '')

            points = points.filter((point) => {
                if (point.type === 'USER') {
                    let pointHasKeywords = true
                    keywordsArray.forEach((word) => {
                        if (!point.additionalInfo.keywords.includes(word)) {
                            pointHasKeywords = false
                        }
                    })

                    return pointHasKeywords
                }
                return false
            })
        }

        dispatch(geographyActionCreators.setMapSubjects(subjects))
        dispatch(geographyActionCreators.setMapPoints(points))
        dispatch(geographyActionCreators.setMapDistricts(districts))
    },

    // specialities
    fetchSpecialities: () => async (dispatch) => {
        return api.speciality
            .getAll()
            .then((res) => {
                dispatch(appDataActionCreators.setSpecialities(res.data.specialities))
                return res
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    createSpeciality: (specialityName, token) => async (dispatch) => {
        return api.speciality
            .create(specialityName, token)
            .then((res) => {
                dispatch(appDataActionCreators.setSpecialities(res.data.specialities))
                dispatch(thunkCreators.noticeUser(res.data.message))
                return res
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    fetchEducation: () => async (dispatch) => {
        return api.education.getAll().then((res) => {
            dispatch(appDataActionCreators.setEducation(res.data.educations))
            return res
        })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    fetchAcademicTitles: () => async (dispatch) => {
        return api.academicTitle.getAll().then((res) => {
            dispatch(appDataActionCreators.setAcademicTitles(res.data.academicTitles))
            return res
        })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    deleteSpecialityById: (specialityId, token) => async (dispatch) => {
        return api.speciality
            .deleteById(specialityId, token)
            .then((res) => {
                dispatch(thunkCreators.fetchSpecialities())
                dispatch(thunkCreators.noticeUser(res.data.message))
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    // scientific statuses
    fetchScientificStatuses: () => async (dispatch) => {
        return api.scientificStatus
            .getAll()
            .then((res) => {
                dispatch(
                    appDataActionCreators.setScientificStatuses(
                        res.data.scientificStatuses,
                    ),
                )
                return res
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    createScientificStatus: (scientificStatusName, token) => async (dispatch) => {
        return api.scientificStatus
            .create(scientificStatusName, token)
            .then((res) => {
                dispatch(
                    appDataActionCreators.setScientificStatuses(
                        res.data.scientificStatuses,
                    ),
                )
                dispatch(thunkCreators.noticeUser(res.data.message))

                return res
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    // auth
    submitRegistration: (registrationFormFields) => async (dispatch) => {
        const formData = objectToFormData(registrationFormFields)

        return api.auth
            .registerUser(formData)
            .then((res) => {
                const {
                    token,
                    userId,
                    role,
                    avatar,
                    name,
                    surname,
                    parentName,
                } = res.data

                localStorage.setItem(
                    'userData',
                    JSON.stringify({
                        userId,
                        token,
                        role,
                        avatar,
                        name,
                        surname,
                        parentName,
                    }),
                )

                document.location.reload()
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    login: (credentials) => async (dispatch) => {
        return api.auth
            .authorizeUser(credentials)
            .then((res) => {
                const {
                    token,
                    userId,
                    role,
                    avatar,
                    name,
                    surname,
                    parentName,
                } = res.data

                localStorage.setItem(
                    'userData',
                    JSON.stringify({
                        userId,
                        token,
                        role,
                        avatar,
                        name,
                        surname,
                        parentName,
                    }),
                )

                document.location.reload()
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    logout: () => async (dispatch) => {
        localStorage.removeItem('userData')
        document.location.reload()
    },

    checkAuth: (userId, token) => async (dispatch) => {
        if (userId && token) {
            return api.auth.checkAuth(token, userId).catch((error) => {
                if (error.response && error.response.status === 403) {
                    dispatch(thunkCreators.logout())
                }
            })
        }
    },

    forgotPassword: (email) => async (dispatch) => {
        return api.auth
            .forgotPassword(email)
            .then((res) => {
                console.log(res)
                dispatch(thunkCreators.noticeUser(res.data.message))
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    // points
    addUserPoint: (userId, token, {geometry, subjectId}) => async (
        dispatch,
    ) => {
        return api.point
            .user(userId, token, {geometry, subjectId})
            .then((res) => {
                dispatch(thunkCreators.noticeUser(res.data.message))
                dispatch(geographyActionCreators.addMapPoint(res.data.pointWithUserData))
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    fetchPoints: () => async (dispatch) => {
        return api.point
            .getAll()
            .then((res) => {
                dispatch(appDataActionCreators.setPoints(res.data.points))
                dispatch(geographyActionCreators.setMapPoints(res.data.points))
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    fetchUniversities: () => async (dispatch) => {
        return api.university
            .getAll()
            .then((res) => {
                dispatch(
                    appDataActionCreators.setUniversities(
                        res.data.universities,
                    ),
                )
                return res
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    fetchLanguages: () => async (dispatch) => {
        return api.language
            .getAll()
            .then((res) => {
                dispatch(
                    appDataActionCreators.setLanguages(
                        res.data.languages,
                    ),
                )
                dispatch(
                    appDataActionCreators.setLanguageLevels(
                        res.data.languageLevels
                    )
                )
                return res
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    fetchScientificInterests: () => async (dispatch) => {
        return api.scientificInterests.getAll().then((res) => {
            dispatch(appDataActionCreators.setScientificInterests(res.data.scientificInterests))
            return res
        }).catch((error) => {
            console.log(error)
            dispatch(thunkCreators.noticeUser(error.response.data.message))
            return error.response
        })
    },

    fetchAgePeriods: () => async (dispatch) => {
        return api.agePeriod.getAll().then((res) => {
            dispatch(appDataActionCreators.setAgePeriods(res.data.agePeriods))
            return res
        }).catch((error) => {
            console.log(error)
            dispatch(thunkCreators.noticeUser(error.response.data.message))
            return error.response
        })
    },

    fetchAvailableScientists: (userId) => async (dispatch) => {
        return api.user.getAvailableScientists(userId).then(res => {
            dispatch(appDataActionCreators.setAvailableScientists(res.data.availableScientists))
            return res
        }).catch((error) => {
            console.log(error)
            dispatch(thunkCreators.noticeUser(error.response.data.message))
            return error.response
        })
    },

    deletePoint: (userId, token) => async (dispatch) => {
        return api.point.deletePoint(userId, token).then((res) => {
            dispatch(thunkCreators.noticeUser(res.data.message))
            dispatch(geographyActionCreators.deleteMapPoint(userId))
        }).catch((error) => {
            console.log(error)
            dispatch(thunkCreators.noticeUser(error.response.data.message))
            return error.response
        })
    },

    // user
    getUserById: (userId) => async (dispatch) => {
        return api.user
            .getById(userId)
            .then((res) => {
                return res.data
            })
            .catch((error) => {
                console.log(error)
            })
    },

    deleteUser: (userId, token) => async (dispatch) => {
        return api.user
            .deleteUserById(userId, token)
            .then((res) => {
                dispatch(thunkCreators.noticeUser(res.data.message))
                dispatch(thunkCreators.logout())
            })
            .catch((error) => {
                console.log(error)
            })
    },

    changeUserProfile: (
        userChangeData,
        userId,
        token,
    ) => async (dispatch) => {
        const formData = objectToFormData(userChangeData)
        console.log(userChangeData)

        return api.user
            .changeById(
                formData,
                userId,
                token,
            )
            .then((res) => {
                const authData = {
                    userId,
                    token,
                    role: res.data.userData.role,
                    avatar: res.data.userData.avatar,
                    name: res.data.userData.name,
                    surname: res.data.userData.surname,
                    parentName: res.data.userData.parentName,
                }
                dispatch(authActionCreators.setAuthData(authData))
                localStorage.setItem('userData', JSON.stringify(authData))

                dispatch(thunkCreators.noticeUser(res.data.message))
                dispatch(thunkCreators.fetchPoints())
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
            })
    },

    // keywords
    addKeyword: (keyword, userId, token) => async (dispatch) => {
        return api.user
            .addKeyword(keyword, userId, token)
            .then((res) => {
                dispatch(thunkCreators.fetchPoints())
                dispatch(thunkCreators.noticeUser(res.data.message))
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    deleteKeyword: (keyword, userId, token) => async (dispatch) => {
        return api.user
            .deleteKeyword(keyword, userId, token)
            .then((res) => {
                dispatch(thunkCreators.fetchPoints())
                dispatch(thunkCreators.noticeUser(res.data.message))
            })
            .catch((error) => {
                console.log(error)
                dispatch(thunkCreators.noticeUser(error.response.data.message))
                return error.response
            })
    },

    // fetch data from server
    fetchAppData: () => async (dispatch) => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        dispatch(thunkCreators.fetchScientificStatuses())
        dispatch(thunkCreators.fetchSpecialities())
        dispatch(thunkCreators.fetchPoints())
        dispatch(thunkCreators.fetchEducation())
        dispatch(thunkCreators.fetchAcademicTitles())
        dispatch(thunkCreators.fetchUniversities())
        dispatch(thunkCreators.fetchAgePeriods())
        dispatch(thunkCreators.fetchScientificInterests())
        dispatch(thunkCreators.fetchLanguages())
        if (userData?.userId) dispatch(thunkCreators.fetchAvailableScientists(userData?.userId))
    },

    // notifications
    noticeUser: (messageText, timeout = 8000, jsx) => (dispatch) => {
        const state = store.getState()
        const messages = state.notifications.messages

        const newMessageId = messages.reduce((id, currentMessage) => {
            if (currentMessage.id >= id) {
                return id + 1
            }
            return id
        }, 1)

        dispatch(
            notificationsActionCreators.addNotification(newMessageId, messageText, jsx),
        )
        setTimeout(() => {
            dispatch(notificationsActionCreators.deleteNotification(newMessageId))
        }, timeout)
    },
}

export default thunkCreators
