import { Typography } from '@mui/material'
import React from 'react'

const Subtitle = (props) => {
  return (
    <Typography variant="h4" component="h3" textAlign="center" sx={{ mb: 2 }}>
      {props.children}
    </Typography>
  )
}

export default Subtitle
