export const searchActionTypes = {
  RESET_SEARCH_FORM: 'RESET_SEARCH_FORM',
  CHANGE_SEARCH_FIELD_VALUE_BY_NAME: 'CHANGE_SEARCH_FIELD_VALUE_BY_NAME',
  
}

const searchActionCreators = {
  resetSearchForm: () => ({
    type: searchActionTypes.RESET_SEARCH_FORM,
  }),

  changeSearchField: (name, value) => ({
    type: searchActionTypes.CHANGE_SEARCH_FIELD_VALUE_BY_NAME,
    payload: {
      name,
      value,
    },
  }),
}

export default searchActionCreators
