import React from 'react'
import { useSelector } from 'react-redux'
import UserPlacemark from './UserPlacemark/UserPlacemark'

const MapPlacemarks = () => {
  const { points, districts, subjects } = useSelector(
    (state) => state.geography,
  )
  const { specialities, scientificStatuses } = useSelector(
    (state) => state.appData,
  )

  const createUserPlacemark = (point, key) => {
    const {
      name,
      surname,
      parentName,
      email,
      keywords,
      userId,
      speciality,
      scientificStatus,
      avatar,
      education,
      honoryTitle,
      university,
      academicTitle
    } = point.additionalInfo

    const districtName = districts.find((district) => {
      return district.subjects.includes(point.subjectId)
    })?.name

    const subjectName = subjects.find((subject) => {
      return subject.geometry.id === point.subjectId
    })?.geometry.properties.hintContent


    const userInfo = {
      name,
      surname,
      parentName,
      email,
      keywords,
      userId,
      subject: subjectName,
      district: districtName,
      scientificStatus: scientificStatus,
      speciality: speciality,
      avatar,
      education,
      honoryTitle,
      university,
      academicTitle
    }

    return (
      <UserPlacemark
        geometry={point.geometry}
        subjectId={point.subjectId}
        userInfo={userInfo}
        key={key}
        point={point}
      />
    )
  }

  return (
    <>
      {points.map((point, index) => {
        if (point.type === 'USER') {
          return createUserPlacemark(point, index)
        }

        return null
      })}
    </>
  )
}

export default MapPlacemarks
