import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import thunkCreators from "../../../redux/thunks";
import Input from "../../common/Input/Input";
import Subtitle from "../../common/Subtitle/Subtitle";
import Keywords from "../../Keywords/Keywords";
import css from "./KeywordsForm.module.scss";

const KeywordsForm = () => {
  const dispatch = useDispatch();
  const [keywords, setKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState("");
  const [message, setMessage] = useState("");
  const userId = useSelector((state) => state.auth.userId);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    dispatch(thunkCreators.getUserById(userId)).then((userData) => {
      const newKeywords = userData.keywords ? userData.keywords : [];
      setKeywords(newKeywords);
    });
  }, [dispatch, userId]);

  const handleInputChange = (event) => {
    let incorrect = false;
    const forbidenSymbols = [";"];

    forbidenSymbols.forEach((symbol) => {
      if (event.target.value.includes(symbol)) {
        incorrect = true;
      }
    });

    if (incorrect) {
      setMessage('Ключевое слово не должно содержать символ ";"');
      return;
    }
    setMessage("");
    setNewKeyword(event.target.value.toLowerCase());
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    await dispatch(thunkCreators.addKeyword(newKeyword, userId, token));
    const userData = await dispatch(thunkCreators.getUserById(userId));
    const newKeywords = userData.keywords ? userData.keywords : [];
    setKeywords(newKeywords);
    setNewKeyword("");
  };

  const handleKeywordDeleteButtonClick = async (wordToDelete) => {
    await dispatch(thunkCreators.deleteKeyword(wordToDelete, userId, token));
    const userData = await dispatch(thunkCreators.getUserById(userId));
    const newKeywords = userData.keywords ? userData.keywords : [];
    setKeywords(newKeywords);
  };

  return (
    <Box>
      <Subtitle>Изменить ключевые слова</Subtitle>
      <Container maxWidth="xl" className={css.container} sx={{ mb: 3 }}>
        <Box className={css.keywordsWrapper}>
          <Keywords words={keywords} onDeleteButtonClick={handleKeywordDeleteButtonClick} />
        </Box>
        <form onSubmit={handleFormSubmit} className={css.form}>
          <Input maxLength="30" onChange={handleInputChange} value={newKeyword} label="Новое ключевое слово" />
          <Typography sx={{ mb: 1 }} color="red" component="p">
            {message}
          </Typography>
          <Button disabled={!newKeyword} variant="contained" type="submit">
            Добавить
          </Button>
        </form>
      </Container>
    </Box>
  );
};

export default KeywordsForm;
