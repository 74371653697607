import { styled } from '@mui/material/styles'
import { Paper, Stack, Typography } from '@mui/material'
import React from 'react'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  maxHeight: '20px',
  marginLeft: '0 !important',
}))

const Keywords = (props) => {
  return (
    <>
      {props.words && props.words.length ? (
        <Stack
          sx={{
            p: 3,
            bgcolor: '#c4c4c4',
            borderRadius: 3,
            height: '100%',
            boxSizing: 'border-box',
            flexWrap: 'wrap',
            gap: '10px',
            flexDirection: 'row',
          }}
        >
          {props.words.map((word, index) => {
            return (
              <Item key={index}>
                {word}{' '}
                {props.removeDeleteButtons ? null : (
                  <span
                    sx={{ color: 'red' }}
                    style={{ cursor: 'pointer' }}
                    component="span"
                    onClick={() => props.onDeleteButtonClick(word)}
                  >
                    &#10060;
                  </span>
                )}
              </Item>
            )
          })}
        </Stack>
      ) : (
        <Typography
          component="h6"
          sx={{
            p: 3,
            bgcolor: '#c4c4c4',
            borderRadius: 3,
            height: '100%',
            boxSizing: 'border-box',
          }}
        >
          Нет ключевых слов
        </Typography>
      )}
    </>
  )
}

export default Keywords
