export const notificationsActionTypes = {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
}

const notificationsActionCreators = {
  addNotification: (id, text, jsx) => ({
    type: notificationsActionTypes.ADD_NOTIFICATION,
    payload: {
      id,
      text,
      jsx
    },
  }),

  deleteNotification: (id) => ({
    type: notificationsActionTypes.DELETE_NOTIFICATION,
    payload: {
      id,
    },
  }),
}

export default notificationsActionCreators
