import { Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import searchActionCreators from '../../redux/reducers/search/actions'
import thunkCreators from '../../redux/thunks'
import Input from '../common/Input/Input'
import DistrictSelect from '../search/DistrictSelect/DistrictSelect'
import KeywordsInput from '../search/KeywordsInput/KeywordsInput'
import ResetButton from '../search/ResetButton/ResetButton'
import ScientificStatusAutocomplete from '../search/ScientificStatusAutocomplete/ScientificStatusAutocomplete'
import SpecialityAutocomplete from '../search/SpecialityAutocomplete/SpecialityAutocomplete'
import SubjectAutocomplete from '../search/SubjectAutocomplete/SubjectAutocomplete'

const MainPageSearchForm = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(searchActionCreators.resetSearchForm())
    dispatch(thunkCreators.filterGeography())
  }, [dispatch])

  return (
    <form
      style={{
        display: 'flex',
        justifyContent: 'right',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h5" sx={{mb: 2}}>Поиск исследователей в нужном регионе по заданным параметрам</Typography>
      <DistrictSelect sx={{ mb: 2 }} />
      <SubjectAutocomplete sx={{ mb: 2 }} />
      <SpecialityAutocomplete sx={{ mb: 2 }} />
      <ScientificStatusAutocomplete sx={{ mb: 2 }} />
      <KeywordsInput sx={{ mb: 2 }} label="Ключевые слова" />
      <ResetButton sx={{ mr: 0, ml: 'auto' }} />
    </form>
  )
}

export default MainPageSearchForm
