import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import appDataReducer from './reducers/appData'
import authReducer from './reducers/auth'
import geographyReducer from './reducers/geography'
import notificationsReducer from './reducers/notifications'
import searchReducer from './reducers/search'

const rootReducer = combineReducers({
  search: searchReducer,
  geography: geographyReducer,
  appData: appDataReducer,
  auth: authReducer,
  notifications: notificationsReducer,
})

const store = createStore(rootReducer, applyMiddleware(thunk))

export default store
