import {createRef, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppRouter from './components/AppRouter/AppRouter'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import Notifications from './components/Notifications/Notifications'
import thunkCreators from './redux/thunks'
import {useHistory} from "react-router-dom";



function App() {
  const dispatch = useDispatch()
  const authData = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(thunkCreators.checkAuth(authData.userId, authData.token))
    dispatch(thunkCreators.fetchAppData())
  }, [dispatch, authData])

  return (
    <div className="App">
      <Header />
      <AppRouter />
      <Notifications />
      <Footer />
    </div>
  )
}

export default App
