import axios from 'axios'

const api = axios.create({
    baseURL: 'api/academic-title',
})

const academicTitleApi = {
    async getAll() {
        return api.get('/')
    },
}

export default academicTitleApi
