export const authActionTypes = {
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  SET_USER_ROLE: 'SET_USER_ROLE',
  SET_USER_ID: 'SET_USER_ID',
  SET_AUTH_DATA: 'SET_AUTH_DATA',
}

const authActionCreators = {
  setAuthData: (authData) => ({
    type: authActionTypes.SET_AUTH_DATA,
    payload: authData,
  }),
  setAuthToken: (token) => ({
    type: authActionTypes.SET_AUTH_TOKEN,
    payload: {
      token,
    },
  }),
  setUserRole: (role) => ({
    type: authActionTypes.SET_USER_ROLE,
    payload: {
      role,
    },
  }),
  setUserId: (userId) => ({
    type: authActionTypes.SET_USER_ID,
    payload: {
      userId,
    },
  }),
}

export default authActionCreators
