import React from 'react'
import { useSelector } from 'react-redux'
import { GeoObject } from 'react-yandex-maps'

const MapRuSubjects = (props) => {
  const subjects = useSelector((state) => state.geography.subjects)

  const poligonOptions = {
    fillOpacity: 0.1,
    strokeColor: '#000',
    strokeOpacity: 0.5,
    fillColor: '#000000f8',
  }

  return (
    <>
      {subjects.map((subject, index) => {
        const subjectData = {
          id: subject.geometry.id,
          name: subject.geometry.properties.hintContent,
        }

        return (
          <GeoObject
            key={index}
            geometry={{
              type: 'Polygon',
              coordinates: subject.geometry.coordinates,
              fillRule: 'nonZero',
            }}
            properties={subject.properties}
            onClick={
              props.onSubjectClick
                ? (event) => props.onSubjectClick(event, subjectData)
                : undefined
            }
            options={poligonOptions}
          />
        )
      })}
    </>
  )
}

export default MapRuSubjects
