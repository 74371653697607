const { notificationsActionTypes } = require('./actions')

const initialState = {
  messages: [],
}

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationsActionTypes.ADD_NOTIFICATION:
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            id: action.payload.id,
            text: action.payload.text,
            jsx: action.payload.jsx
          },
        ],
      }

    case notificationsActionTypes.DELETE_NOTIFICATION:
      return {
        ...state,
        messages: state.messages.filter((message) => {
          return message.id !== action.payload.id
        }),
      }

    default:
      return state
  }
}

export default notificationsReducer
