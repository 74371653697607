import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import thunkCreators from '../../../../redux/thunks'

const SpecialitiesList = () => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const specialities = useSelector((state) => state.appData.specialities)

  const listItems = specialities.map((speciality) => {
    const handleDeleteButtonClick = () => {
      dispatch(thunkCreators.deleteSpecialityById(speciality._id, token))
    }

    return (
      <ListItem
        sx={{ bgcolor: '#c4c4c4', p: 2, pl: 3, mb: 2, borderRadius: '5px' }}
      >
        <ListItemText
          key={speciality._id}
          primary={speciality.name}
        ></ListItemText>
        <ListItemButton
          onClick={handleDeleteButtonClick}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            maxWidth: '120px',
          }}
        >
          <ListItemIcon>
            <DeleteIcon sx={{ color: 'darkred' }} />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    )
  })

  return <List>{listItems}</List>
}

export default SpecialitiesList
