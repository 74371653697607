import { useSelector } from "react-redux";
import Select from "../common/Select/Select";

const LanguageSelect = (props) => {
    const { value, onChange, name, required, sx, label } = props;

    const language = useSelector((state) => state.appData.languages);

    const languageSelectOptions = language.map((status) => {
        return {
            key: status._id,
            value: status._id,
            text: status.name,
        };
    });

    const options = languageSelectOptions
        .map((option) => {
            return option.text
        })
        .sort()

    return (
        <Select
            label={label ? label : "Язык*"}
            options={options}
            onChange={onChange}
            value={value}
            name={name}
            sx={sx}
            required={required}
        />
    );
};

export default LanguageSelect;
