import {Box, Container} from '@mui/material'
import React from 'react'
import MainPageMap from '../../components/maps/MainPageMap/MainPageMap'
import SearchForm from '../../components/MainPageSearchForm/MainPageSearchForm'
import css from './MainPage.module.scss'

const MainPage = () => {
    return (
        <Container maxWidth="xl" className="container" sx={{mt: 10}}>
            <Box className={css.wrapper}>
                <Box className={css.form}>
                    <SearchForm/>
                </Box>
                <MainPageMap/>
            </Box>
        </Container>
    )
}

export default MainPage
