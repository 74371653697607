import React from 'react'
import { Autocomplete as MUIAutocomplete, TextField } from '@mui/material'

const Autocomplete = (props) => {
  return (
      <MUIAutocomplete
          freeSolo={props.freeSolo}
        sx={props.sx}
        noOptionsText={
          props.noOptionsText ? props.noOptionsText : 'Нет доступных вариантов'
        }
        id={props.id}
          getOptionLabel={props.getOptionLabel}
        options={props.options}
        renderInput={(params) => (
          <TextField
            name={props.name}
            {...params}
            label={props.label}
            InputLabelProps={{ style: { backgroundColor: 'white' } }}
          />
        )}
        value={props.value}
          onInputChange={props.onInputChange}
          inputValue={props.inputValue}
        onChange={props.onChange}
      />
  )
}

export default Autocomplete
