import { useSelector } from "react-redux";
import Select from "../common/Select/Select";

const EducationSelect = (props) => {
    const { value, onChange, name, required, sx, label } = props;

    const education = useSelector((state) => state.appData.education);

    const educationSelectOptions = education.map((status) => {
        return {
            key: status._id,
            value: status._id,
            text: status.name,
        };
    });

    const options = educationSelectOptions
        .map((option) => {
            return option.text
        })
        .sort()

    return (
        <Select
            label={label ? label : "Сведения об образовании*"}
            options={options}
            onChange={onChange}
            value={value}
            name={name}
            sx={sx}
            required={required}
        />
    );
};

export default EducationSelect;
