import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Container } from '@mui/material'
import routePaths from '../../routes'
import Title from '../../components/common/Title/Title'
import Input from '../../components/common/Input/Input'
import { useDispatch } from 'react-redux'
import thunkCreators from '../../redux/thunks'

const AuthorizationPage = () => {
  const dispatch = useDispatch()
  const [formFields, setFormFields] = useState({
    email: '',
    password: '',
  })

  const handleFormSubmit = (event) => {
    event.preventDefault()
    dispatch(thunkCreators.login(formFields))
  }

  const handleInputChange = (event) => {
    const value =
      event.target.name === 'email'
        ? event.target.value.toLowerCase()
        : event.target.value

    setFormFields({
      ...formFields,
      [event.target.name]: value,
    })
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <Container
        maxWidth="sm"
        sx={{ mt: 10, minHeight: 'calc(100vh - 450px)' }}
      >
        <Title>Авторизация</Title>
        <Input
          label="Email"
          name="email"
          onChange={handleInputChange}
          value={formFields.email}
          required
          type="email"
        />

        <Input
          label="Пароль"
          name="password"
          onChange={handleInputChange}
          required
          type="password"
          minLength="8"
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
            flexWrap: 'wrap',
          }}
        >
          <Button variant="contained" type="submit">
            Войти
          </Button>
          <Link
            to={routePaths.guest.REGISTRATION}
            style={{ textDecoration: 'none' }}
          >
            <Button sx={{ textTransform: 'none' }}>Нет аккаунта?</Button>
          </Link>
          <Link
            to={routePaths.guest.FORGOT_PASSWORD}
            style={{ textDecoration: 'none' }}
          >
            <Button sx={{ textTransform: 'none' }}>Забыли пароль?</Button>
          </Link>
        </Box>
      </Container>
    </form>
  )
}

export default AuthorizationPage
