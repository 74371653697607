import speciality from './paths/speciality'
import scientificStatus from './paths/scientific-status'
import auth from './paths/auth'
import point from './paths/point'
import user from "./paths/user"
import education from './paths/education'
import academicTitle from "./paths/academic-title";
import university from "./paths/university";
import language from './paths/language'
import scientificInterests from "./paths/scientific-interest";
import agePeriod from './paths/age-period'

const api = {
  speciality,
  scientificStatus,
  auth,
  point,
  user,
  education,
  academicTitle,
  university,
  language,
  scientificInterests,
  agePeriod
}

export default api
