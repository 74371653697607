import React, {useEffect, useState} from 'react'
import {Placemark} from 'react-yandex-maps'
import ReactDOMServer from 'react-dom/server'
import BaloonContent from './UserBaloonContent/UserBaloonContent'
import PropTypes from 'prop-types'
import {useDispatch} from "react-redux";
import thunk from "redux-thunk";
import thunkCreators from "../../../../redux/thunks";
import {Link} from "react-router-dom";
import {historyRef} from "../../../AppRouter/AppRouter";

window.balloonClickHandler = (userId) => {
    historyRef.current.replace(`/user/${userId}`)
}

const UserPlacemark = (props) => {
    const content = <BaloonContent {...props.userInfo} />
    const dispatch = useDispatch()

    const {userId} = props.userInfo

    const caption = `${props.userInfo.surname} ${props.userInfo.name[0]}. ${props.userInfo.parentName[0]}`

    const balloonContent = ReactDOMServer.renderToString(content)

    const button = `<button
        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1ujsas3"
        tabIndex="0" type="button" id="toUserProfile">Перейти в профиль</button>`

    const buttonWithFunction = `<button
        className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1ujsas3"
        tabIndex="0" type="button" onclick="window.balloonClickHandler(props.userinfo.userId)" id="toUserProfile">Перейти в профиль</button>`



    balloonContent.replace(button, buttonWithFunction)

    const placemarkProperties = {
        balloonContentBody: balloonContent,
        balloonContentFooter: `<button style="border: none; background-color: white; text-decoration: underline; color: #0000EE;" onclick="window.balloonClickHandler('${userId}')">Перейти в профиль</button>`,
        hintContent: caption,
        clusterCaption: caption,
        clusterContentHeader: '',
        clusterBalloonContentLayoutHeight: 350,

        ...props.properties,
    }

    const clickHandler = (event, point) => {
        if (!props.dontNeedToStopPropagation) {
            event.stopPropagation()
        }
        if (props.onClick) {
            props.onClick(event)
        }
    }

    return (
        <Placemark
            onClick={(event) => clickHandler(event, props.point)}
            geometry={props.geometry}
            modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
            properties={placemarkProperties}
        />
    )
}

UserPlacemark.propTypes = {
    userInfo: PropTypes.shape({
        subject: PropTypes.string,
        district: PropTypes.string,
        userId: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
        parentName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        speciality: PropTypes.string.isRequired,
        scientificStatus: PropTypes.string.isRequired,
        keywords: PropTypes.arrayOf(PropTypes.string),
        avatar: PropTypes.string,
    }).isRequired,
    geometry: PropTypes.arrayOf(PropTypes.number).isRequired,
    subjectId: PropTypes.string,
}

export default UserPlacemark
