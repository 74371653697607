import { Container } from '@mui/material'
import React from 'react'
import ScientificStatuses from '../../components/Admin/ScientificStatuses/ScientificStatuses'
import Specialities from '../../components/Admin/Specialities/Specialities'
import Title from '../../components/common/Title/Title'

const AdminPage = () => {
  return (
    <Container maxWidth="xl" className="container">
      <Title>Кабинет администратора</Title>
      <Specialities />
      <ScientificStatuses />
    </Container>
  )
}

export default AdminPage
