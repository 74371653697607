import axios from 'axios'

const api = axios.create({
  baseURL: 'api/auth',
})

const specialityAPI = {
  async registerUser(registrationFormData) {
    return api.post('/registration', registrationFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  async authorizeUser(credentials) {
    return api.post('/login', credentials)
  },

  async checkAuth(token, userId) {
    return api.get(`/check-auth/${userId}`, {
      headers: {
        Authorization: token,
      },
    })
  },

  async forgotPassword(email) {
    return api.post('/forgot-password', { email })
  },
}

export default specialityAPI
