import { Button, FormControl, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import thunkCreators from '../../../../redux/thunks'
import Subtitle from '../../../common/Subtitle/Subtitle'

const AddSpecialtityForm = () => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const [specialityName, setSpecialityName] = useState('')

  const handleSpecialityNameChange = (event) => {
    setSpecialityName(event.target.value)
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    dispatch(thunkCreators.createSpeciality(specialityName, token))
    setSpecialityName('')
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <TextField
          value={specialityName}
          onChange={handleSpecialityNameChange}
          required
          label="Название специальности"
        />
      </FormControl>

      <Button
        variant="contained"
        sx={{ m: '0 auto', display: 'flex' }}
        type="submit"
      >
        Добавить
      </Button>
    </form>
  )
}

export default AddSpecialtityForm
