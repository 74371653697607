import { authActionTypes } from './actions'

const userData = JSON.parse(localStorage.getItem('userData'))

const initialState = {
  token: userData ? userData.token : null,
  userId: userData ? userData.userId : null,
  role: userData ? userData.role : null,
  avatar: userData ? userData.avatar : null,
  name: userData ? userData.name : null,
  surname: userData ? userData.surname : null,
  parentName: userData ? userData.parentName : null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.SET_AUTH_DATA:
      return action.payload

    case authActionTypes.SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      }

    case authActionTypes.SET_USER_ID:
      return {
        ...state,
        userId: action.payload.userId,
      }

    case authActionTypes.SET_USER_ROLE:
      return {
        ...state,
        role: action.payload.role,
      }

    default:
      return state
  }
}

export default authReducer
