import axios from "axios";

const api = axios.create({
  baseURL: "api/scientific-status",
});

const scientificStatusAPI = {
  async create(name, token) {
    return api.post(
      "/",
      {
        name,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  },
  async getAll() {
    return api.get("/");
  },
};

export default scientificStatusAPI;
